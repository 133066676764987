import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import axios from "axios";
import AdminHeader from "./../layouts/AdminHeader";
import AdminSider from "./../layouts/AdminSider";
import { useToast } from "@chakra-ui/react";
import BASE_URL from "../baseUrl/baseUrl";

const AdminComplain = (props) => {
    const navigate = useNavigate();
    const toast = useToast();
    const [data, setData] = useState([]);
    const [flag, setFlag] = useState(false);
    const [error, setError] = useState(false);
    const [errorMsg, setErrorMsg] = useState("");
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const [collapsed, setCollapsed] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);


    const fetchComplaints = (page) => {
        axios
            .get(`${BASE_URL}/getAllComplains?page=${page}&limit=10`)
            .then((res) => {
                setFlag(true);
                setData(res.data.data);
                setTotalPages(res.data?.totalPages);
            })
            .catch((err) => {
                setError(true);
                setErrorMsg(err.message);
            });
    };

    useEffect(() => {
        fetchComplaints(currentPage);
    }, [currentPage]);

    const DetailPage = (data) => {
        navigate(`/admin/ComplainDetail`, {
            state: data
        });
    };

    const deleteCategory = (id) => {
        axios
            .delete(`${BASE_URL}/delete/${id}`)
            .then((res) => {
                const datafilter = data.filter((item, index) => item._id !== id);
                setData(datafilter);
                toast({
                    title: "Success",
                    description: "Complain deleted successfully.",
                    status: "success",
                    duration: 2000,
                    variant: "left-accent",
                    position: "top-right",
                    isClosable: true,
                });
            })
            .catch((err) => {
                setError(true);
                setErrorMsg(err);
            });
    };

    const handlePageChange = (newPage) => {
        setCurrentPage(newPage);
    };

    const renderPaginationButtons = () => {
        const buttons = [];
        const maxButtonsToShow = 5;
        const halfWay = Math.floor(maxButtonsToShow / 2);

        let startPage, endPage;

        if (totalPages <= maxButtonsToShow) {
            startPage = 1;
            endPage = totalPages;
        } else if (currentPage <= halfWay) {
            startPage = 1;
            endPage = maxButtonsToShow;
        } else if (currentPage + halfWay >= totalPages) {
            startPage = totalPages - maxButtonsToShow + 1;
            endPage = totalPages;
        } else {
            startPage = currentPage - halfWay;
            endPage = currentPage + halfWay;
        }

        for (let i = startPage; i <= endPage; i++) {
            buttons.push(
                <li key={i} className={`page-item ${currentPage === i ? 'active' : ''}`}>
                    <button
                        className="page-link"
                        onClick={() => handlePageChange(i)}
                    >
                        {i}
                    </button>
                </li>
            );
        }

        return buttons;
    };

    const toggleCollapsed = () => {
        setCollapsed(!collapsed);
    };
    return (
        <div>
            <Helmet>
                <title>Buy Home For Less | Admin Category</title>
                <meta name="description" content="#" />
            </Helmet>
            <AdminHeader collapsed={collapsed} toggleCollapsed={toggleCollapsed} />
            <div style={{ display: "flex" }}>
                <AdminSider
                    url={props.url}
                    isOpen={sidebarOpen}
                    onStateChange={(state) => setSidebarOpen(state.isOpen)}
                    collapsed={collapsed}
                    toggleCollapsed={toggleCollapsed}
                />
                <div style={{ flex: 1, padding: "20px", overflowX: "scroll" }}>
                    <div className="text-center" style={{ margin: "20px" }}>
                        <h2>Complaints</h2>
                    </div>
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "right",
                            width: "94%",
                            margin: "1%",
                        }}
                    ></div>
                    <div className="table-container-main">
                        <div className="table-container-child">
                            <table
                                className="table-striped table-bordered text-center"
                                style={{ width: "90%", margin: "1%" }}
                            >
                                <thead>
                                    <tr>
                                        <th>No</th>
                                        <th>Name</th>
                                        <th>Email</th>
                                        <th>Detail</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {data.length === 0 && flag ? (
                                        <tr>
                                            <td colSpan="6">No Data</td>
                                        </tr>
                                    ) : data.length === 0 && flag === false ? (
                                        <tr>
                                            <td colSpan="6">Loading ...</td>
                                        </tr>
                                    ) : (
                                        data?.map((res, key) => {
                                            return (
                                                <tr key={key}>
                                                    <td>{(currentPage - 1) * 10 + (key + 1)}</td>
                                                    <td>{res.firstName}</td>
                                                    <td>{res.email}</td>
                                                    {/* <td>
                            <img
                              src={`${res.icon}`}
                              alt="No Icon"
                              style={{ width: "60px" }}
                            />
                          </td>
                          <td>
                            <img
                              src={`${res.img}`}
                              alt="NoImage"
                              style={{ width: "60px" }}
                            />
                          </td> */}
                                                    <td>
                                                        <button
                                                            className="btn btn-primary w-[100px] mb-1"
                                                            onClick={() => DetailPage(res)}
                                                            style={{ borderRadius: "5px" }}
                                                        >
                                                            Detail
                                                        </button>
                                                    </td>
                                                    <td>
                                                        <button
                                                            className="btn btn-danger w-[100px] mb-1"
                                                            onClick={() => deleteCategory(res._id)}
                                                            style={{ borderRadius: "5px" }}
                                                        >
                                                            <span className="fa fa-trash mr-1"></span>
                                                            Delete
                                                        </button>
                                                    </td>
                                                </tr>
                                            );
                                        })
                                    )}
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                        {error ? (
                            <div
                                style={{
                                    marginTop: "20px",
                                    display: "flex",
                                    justifyContent: "space-between",
                                    width: "70%",
                                    backgroundColor: "#FF3131",
                                    color: "white",
                                    padding: "10px 20px 10px 20px",
                                    borderRadius: "5px",
                                    alignItems: "center",
                                }}
                            >
                                <span>{error ? `${errorMsg}` : ""}</span>
                                <div
                                    style={{
                                        cursor: "pointer",
                                        display: "flex",
                                        justifyContent: "center",
                                        border: "white 2px solid",
                                        borderRadius: "30px",
                                        width: "40px",
                                        backgroundColor: "#FF3131",
                                        height: "40px",
                                    }}
                                    onClick={() => {
                                        setError(false);
                                    }}
                                >
                                    <p
                                        style={{
                                            color: "white",
                                            alignItems: "center",
                                            marginTop: "3px",
                                        }}
                                    >
                                        x
                                    </p>
                                </div>
                            </div>
                        ) : (
                            ""
                        )}
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '40px' }}>
                        <nav aria-label="Page navigation example">
                            <ul className="pagination justify-content-center gap-2">
                                <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                                    <button
                                        className="page-link"
                                        onClick={() => handlePageChange(currentPage - 1)}
                                        disabled={currentPage === 1}
                                    >
                                        Previous
                                    </button>
                                </li>
                                {renderPaginationButtons()}
                                <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
                                    <button
                                        className="page-link"
                                        onClick={() => handlePageChange(currentPage + 1)}
                                        disabled={currentPage === totalPages}
                                    >
                                        Next
                                    </button>
                                </li>
                            </ul>
                        </nav>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AdminComplain;
