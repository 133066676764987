import React, { useEffect } from "react";
import { Button } from "@chakra-ui/react";
import { Helmet } from "react-helmet";
import AdminHeader from "./AdminHeader";
import { useState } from "react";
import AdminSider from "./AdminSider";
import { useToast } from "@chakra-ui/react";
import BASE_URL from "../baseUrl/baseUrl";
import axios from "axios";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Textarea,
  useDisclosure,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";

function Propertylisting(props) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isLoading, setIsLoading] = useState('');
  const [isLoading2, setIsLoading2] = useState(false);
  const [message, setMessage] = useState("");
  const [selectedId, setSelectedId] = useState(null);
  const [collapsed, setCollapsed] = useState(false);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [data, setData] = useState([]);
  const [flag, setFlag] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [showModal, setShowModal] = useState(false);
  const [totalPage, settotalPage] = useState();
  const toast = useToast();
  const navigate = useNavigate();
  const limit = 10;
  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
  };
  const fetchlisting = async (page) => {
    const response = await axios.get(
      `${BASE_URL}/submitlisting/getUnApproveListing?page=${page}&limit=${limit}`
    );
    if (response) {
      setFlag(true);
      console.log(response);
      settotalPage(response?.data?.totalPage);
      setData(response?.data?.result);
    }
  };
  useEffect(() => {
    if (currentPage) {
      fetchlisting(currentPage);
      return;
    }
  }, [currentPage]);

  const handlePageChange = (pageNumber) => {
    // console.log(currentPage,pageNumber);
    setCurrentPage(pageNumber);
  };
  const handleReject = (id) => {
    setSelectedId(id);
    onOpen(); // Open the modal when 'Reject' is clicked
  };
  const handleRejectCall = async () => {
    setIsLoading2(true); // Set loading state to true
    const body = {
      reason: message, // Add your rejection message here
      type: "reject",
    };
  
    try {
      // API call to reject the listing
      const response = await fetch(
        `${BASE_URL}/submitlisting/approvedListing/${selectedId}`,
        {
          method: "POST",
          body: JSON.stringify(body),
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
  
      if (response.ok) {
        // Success toast notification
        toast({
          title: "Rejected",
          description: "The request has been rejected successfully.",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
        
        setMessage(""); // Clear the message
        handleClose();  // Close the modal
        
        // Instead of reloading the page, update your state or fetch data again
        // Call your function here to refresh the list
        // Example: fetchListings(); 
      } else {
        // Error toast notification
        toast({
          title: "Error",
          description: "Something went wrong. Please try again.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    } catch (error) {
      // Handle error scenario
      toast({
        title: "Error",
        description: "Network error or server issue.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsLoading2(null); // Stop loading after API call
    }
    window.location.reload()
  };
  
  const handleApproved = async (id) => {
    setIsLoading(id); // Loader start karein
    try {
      // API call for approving the listing
      const response = await fetch(
        `${BASE_URL}/submitlisting/approvedListing/${id}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            type: "approved",
          }),
        }
      );

      if (response.ok) {
        toast({
          title: "Approved",
          description: "The request has been Approved successfully.",
          status: "success",
          duration: 5000,
          isClosable: true,
        });

        // Update state here or refresh the data instead of reloading the page
        // Example: Call fetch function again to update the list
      } else {
        toast({
          title: "Error",
          description: "Something went wrong. Please try again.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    } catch (error) {
      // Handle error
      toast({
        title: "Error",
        description: "Network error or server issue.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      // setTimeout(()=>{
      //   setIsLoading(null)
      // },3000) // Loader ko stop karein after API call
      setIsLoading(null)
    }
    window.location.reload()
  };

  const handleClose = () => {
    setMessage(""); // Clear the message
    onClose(); // Call the parent's onClose function
  };
  function convertDateToInputFormat(dateString) {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  }

  return (
    <div>
      <Helmet>
        <title>Buy Home For Less | Admin Property listing</title>
        <meta name="description" content="#" />
      </Helmet>
      <AdminHeader collapsed={collapsed} toggleCollapsed={toggleCollapsed} />
      <div style={{ display: "flex" }}>
        <AdminSider
          url={props.url}
          isOpen={sidebarOpen}
          onStateChange={(state) => setSidebarOpen(state.isOpen)}
          collapsed={collapsed}
          toggleCollapsed={toggleCollapsed}
        />
        <div style={{ flex: 1, padding: "20px", overflowX: "scroll" }}>
          <div className="text-center" style={{ margin: "20px" }}>
            <h2>My Properties</h2>
          </div>
          <div></div>
          <div
            className="container table-responsive"
            style={{ overflowX: "scroll" }}
          >
            <table className="table table-striped table-bordered text-center">
              <thead>
                <tr>
                  <th>No</th>
                  <th>Date</th>
                  <th>Property Name</th>
                  <th>Property Type</th>
                  <th>Listing Type</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {data?.length === 0 && flag ? (
                  <tr className="text-center">
                    <td colSpan="9">No Data</td>
                  </tr>
                ) : data.length === 0 && flag === false ? (
                  <tr className="text-center">
                    <td colSpan="9">Loading ...</td>
                  </tr>
                ) : (
                  data?.map((res, key) => {
                    return (
                      <tr key={key}>
                        <td>{(currentPage - 1) * 10 + (key + 1)}</td>
                        <td>{convertDateToInputFormat(res?.createdAt)}</td>
                        <td>
                          {res.BasicInformation.name || ""}
                        </td>
                        <td>{res.BasicInformation.type}</td>
                        <td>{res.BasicInformation.listingType}</td>
                        <td>
                          {/* <button
                            className={`rounded bg-success text-white px-3 py-2 mr-1`}
                            style={{ width: "150px" }}
                            onClick={() => handleApproved(res?._id)}
                          >
                            Approved
                          </button> */}
                          <Button
                            isLoading={isLoading === res._id}
                            loadingText="Submitting"
                            colorScheme="green"
                            onClick={() => handleApproved(res?._id)}
                            className="mr-1"
                          >
                            Approved
                          </Button>
                          <Button
                            // isLoading={isLoading}
                            // loadingText="Submitting"
                            colorScheme="red"
                            onClick={() => handleReject(res?._id)}
                            className="mr-1"
                          >
                            Reject
                          </Button>
                          {/* <button
                            className={`rounded bg-danger text-white px-3 py-2 mr-2`}
                            style={{ width: "150px" }}
                            onClick={() => handleReject(res?._id)}
                          >
                            Reject
                          </button> */}
                          <Button
                            // isLoading={isLoading}
                            // loadingText="Submitting"
                            colorScheme="blue"
                            onClick={() =>
                              navigate(`/admin/property/${res?._id}/detail`)
                            }
                            className="mr-1"
                          >
                            Detail
                          </Button>
                          {/* <button
                            className={`rounded bg-primary text-white px-3 py-2 `}
                            style={{ width: "150px" }}
                            onClick={() =>
                              navigate(`/admin/property/${res?._id}/detail`)
                            }
                          >
                            Detail
                          </button> */}
                        </td>
                      </tr>
                    );
                  })
                )}
              </tbody>
            </table>
          </div>
          <div className="mb-4">
            {totalPage === 1 ? null : (
              <nav aria-label="Page navigation">
                <ul className="pagination justify-content-center">
                  <li
                    className={`page-item ${
                      currentPage === 1 ? "disabled" : ""
                    }`}
                  >
                    <button
                      className="page-link"
                      onClick={() => handlePageChange(currentPage - 1)}
                    >
                      Previous
                    </button>
                  </li>
                  {[...Array(totalPage)].map((_, index) => (
                    <li
                      key={index}
                      className={`page-item ${
                        currentPage === index + 1 ? "active" : ""
                      }`}
                    >
                      <button
                        className="page-link"
                        onClick={() => handlePageChange(index + 1)}
                      >
                        {index + 1}
                      </button>
                    </li>
                  ))}
                  <li
                    className={`page-item ${
                      currentPage === totalPage ? "disabled" : ""
                    }`}
                  >
                    <button
                      className="page-link"
                      onClick={() => handlePageChange(currentPage + 1)}
                    >
                      Next
                    </button>
                  </li>
                </ul>
              </nav>
            )}
          </div>
        </div>
      </div>
      <Modal isOpen={isOpen} onClose={handleClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Enter Rejection Message</ModalHeader>
          <ModalBody>
            <Textarea
              placeholder="Enter reason for rejection"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
            />
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={handleClose}>
              Close
            </Button>
            {/* <Button variant="ghost" onClick={handleRejectCall}>
              Submit
            </Button> */}
            <Button
              // isLoading={isLoading2 == }
              loadingText="Submitting"
              colorScheme="blue"
              variant="ghost"
              onClick={handleRejectCall}
            >
              Submit
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </div>
  );
}

export default Propertylisting;
