import React, { useEffect, useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  FormControl,
  FormLabel,
  Input,
  Button,
  Box,
  Image,
} from "@chakra-ui/react";
import BASE_URL from "../baseUrl/baseUrl";
import axios from "axios";

function Social({ isOpen, setisLink, FilterData ,setFilterData}) {
  const initialRef = React.useRef(null);
  const finalRef = React.useRef(null);
  const [socialLink, setSocialLink] = useState("");
  const [imageSrc, setImageSrc] = useState(null);
  const [name, setName] = useState();
  const [selectedFile, setSelectedFile] = useState(null); // State to store the selected file

  const onClose = () => {
    setisLink(false);
    setName("")
    setImageSrc("")
    setSocialLink("")
    setFilterData('')
  };

  const handleFileUpload = (event) => {
    const image = event.target.files[0];
    setSelectedFile(image); // Store the file

    if (image) {
      const reader = new FileReader();

      reader.onload = function (e) {
        const imageUrl = e.target.result; // DataURL
        setImageSrc(imageUrl); // Set the image URL for preview
      };

      reader.readAsDataURL(image); // Read the image file as DataURL
    }
  };

  const handleSubmit = async () => {
    if (!socialLink || !selectedFile || !name) {
      alert("Please provide both image and social link and Social Name");
      return;
    }

    const formData = new FormData();
    formData.append("link", socialLink);
    formData.append("images", selectedFile);
    formData.append("name", name);

    try {
      let response
      if(FilterData){
        response=await axios.put(`${BASE_URL}/socialLinks/${FilterData[0]?._id}`,formData)
        onClose();
      }
      else{
        response = await fetch(`${BASE_URL}/addLink`, {
         method: "POST",
         body: formData,
       });
      }

      if (response.ok) {
        const data = await response.json();
        console.log("Success:", data);
        onClose(); // Close the modal on successful submission
      } else {
        console.error("Error:", response.statusText);
      }
    } 
    catch (error) {
      console.error("Error:", error);
    }
   
  };
  useEffect(() => {
    if (FilterData) {
      console.log(FilterData);
      setSelectedFile(FilterData[0]?.image);
      setName(FilterData[0]?.name);
      setSocialLink(FilterData[0]?.link);
      setImageSrc(FilterData[0]?.image);
    }
  }, []);
  return (
    <>
      <Modal
        initialFocusRef={initialRef}
        finalFocusRef={finalRef}
        isOpen={isOpen}
        onClose={onClose}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Add Social Media Link</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            {/* Social Link Input */}
            <FormControl>
              <FormLabel>Social Media Link</FormLabel>
              <Input
                // ref={initialRef}
                placeholder="Enter Social Link"
                value={socialLink}
                onChange={(e) => setSocialLink(e.target.value)}
              />
            </FormControl>
            <FormControl>
              <FormLabel>Social Media Name</FormLabel>
              <Input
                // ref={initialRef}
                placeholder="Enter Social Name"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </FormControl>

            {/* Social Image File Upload */}
            {/* <FormControl mt={4}>
              <FormLabel>Upload Social Image</FormLabel>
              <Input
                type="file"
                placeholder="Upload social image"
                sx={{
                  border: "none",
                  boxShadow: "none",
                  "&:focus": {
                    border: "none",
                    boxShadow: "none",
                  },
                }}
                onChange={handleFileUpload}
              />
            </FormControl> */}
            <div className="mt-3">
              <label
                htmlFor="file-upload"
                style={{
                  display: "inline-block",
                  // backgroundColor: "#007bff",
                  color: "black",
                  border:"1px solid black",
                  padding: "10px 20px",
                  borderRadius: "4px",
                  cursor: "pointer",
                }}
              >
                Upload social image
              </label>
              <Input
                id="file-upload"
                type="file"
                placeholder="Upload social image"
                sx={{
                  display: "none", // Hide the default file input
                }}
                onChange={handleFileUpload}
              />
            </div>

            {imageSrc && (
              <Box mt={4}>
                <Image
                  src={imageSrc}
                  alt="Uploaded Image"
                  boxSize="150px"
                  objectFit="cover"
                />
              </Box>
            )}
          </ModalBody>

          {/* Modal Footer with action buttons */}
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={handleSubmit}>
              {FilterData ? "update" : "Save"}
            </Button>
            <Button onClick={onClose}>Cancel</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

export default Social;
