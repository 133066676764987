import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import axios from "axios";
import AdminHeader from "./../layouts/AdminHeader";
import AdminSider from "./../layouts/AdminSider";
import BASE_URL from "../baseUrl/baseUrl";

const AdminUser = (props) => {
  const navigate = useNavigate();
  const [state, setState] = useState([]);
  const [flag, setFlag] = useState(false);
  const [error, setError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [collapsed, setCollapsed] = useState(false);
  const itemsPerPage = 10;
  const totalPages = Math.ceil(state.length / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const currentData = state.slice(startIndex, startIndex + itemsPerPage);

  useEffect(() => {
    axios
      .get(`${BASE_URL}/admin/get-users`)
      .then((res) => {
        setFlag(true);
        console.log(res?.data);
        setState(res.data);
      });
  }, []);

  const addUser = () => {
    navigate("/admin/user/create");
  };

  const editUser = (id) => {
    navigate(`/admin/user/${id}`);
  };

  const deleteUser = (id) => {
    axios
      .delete(`${BASE_URL}/admin/delete/user/${id}`)
      .then((res) => {
        setState(res.data.result);
      })
      .catch((err) => {
        setError(true);
        setErrorMsg(err.message);
      });
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
  };

  return (
    <div>
      <Helmet>
        <title>Buy Home For Less | Admin User</title>
        <meta name="description" content="#" />
      </Helmet>
      <AdminHeader collapsed={collapsed} toggleCollapsed={toggleCollapsed} />
      <div style={{ display: "flex" }}>
        <AdminSider
          url={props.url}
          isOpen={sidebarOpen}
          onStateChange={(state) => setSidebarOpen(state.isOpen)}
          collapsed={collapsed}
          toggleCollapsed={toggleCollapsed}
        />
        <div style={{ flex: 1, padding: "20px", overflowX: "scroll" }}>
          <div className="text-center" style={{ margin: "20px" }}>
            <h2>Users</h2>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "right",
              width: "94%",
              margin: "1%",
            }}
          >
            <button
              type="button"
              className="btn btn-success"
              onClick={() => addUser()}
            >
              <span className="fa fa-plus"></span> Add User
            </button>
          </div>
          <div className="table-container-main">
            <div className="table-container-child">
              <table className="table text-center " style={{ width: "100%", margin: "1%" }}>
                <thead>
                  <tr>
                    <th scope="col">Id</th>
                    <th scope="col">Name</th>
                    <th scope="col">Email or Phone</th>
                    <th scope="col">Role</th>
                    <th scope="col">Admin</th>
                    <th scope="col">OPT Code</th>
                    <th scope="col">Verify</th>
                    <th scope="col">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {currentData?.length === 0 && flag ? (
                    <tr className="text-center">
                      <td colSpan="8">No Data</td>
                    </tr>
                  ) : currentData?.length === 0 && flag === false ? (
                    <tr className="text-center">
                      <td colSpan="8">Loading ...</td>
                    </tr>
                  ) : (
                    currentData?.map((res, key) => {
                      return (
                        <tr key={key}>
                           <td>{(currentPage - 1) * 10 + (key + 1)}</td>
                          <td>{res.name}</td>
                          <td>{res.email}</td>
                          <td>{res.user}</td>
                          <td>{`${res.isAdmin}`}</td>
                          <td>{res.opt}</td>
                          <td>{`${res.verified}`}</td>
                          <td>
                            <button
                              className="btn btn-primary mr-2 mb-1 w-[100px] table-btn"
                              onClick={() => editUser(res._id)}
                              style={{ borderRadius: "5px", marginRight: "10px" }}
                            >
                              <span className="fa fa-edit mr-1"></span>
                              Edit
                            </button>
                            <button
                              className="btn btn-danger mb-1 w-[100px]"
                              onClick={() => deleteUser(res._id)}
                              style={{ borderRadius: "5px" }}
                            >
                              <span className="fa fa-trash mr-1"></span>
                              Delete
                            </button>
                          </td>
                        </tr>
                      );
                    })
                  )}
                </tbody>
              </table>
            </div>
          </div>
          <div style={{ display: "flex", justifyContent: "center" }} className="mb-4">
            {error ? (
              <div
                style={{
                  marginTop: "20px",
                  display: "flex",
                  justifyContent: "space-between",
                  width: "70%",
                  backgroundColor: "#FF3131",
                  color: "white",
                  padding: "10px 20px 10px 20px",
                  borderRadius: "5px",
                  alignItems: "center",
                }}
              >
                <span>{error ? `${errorMsg}` : ""}</span>
                <div
                  style={{
                    cursor: "pointer",
                    display: "flex",
                    justifyContent: "center",
                    border: "white 2px solid",
                    borderRadius: "30px",
                    width: "40px",
                    backgroundColor: "#FF3131",
                    height: "40px",
                  }}
                  onClick={() => {
                    setError(false);
                  }}
                >
                  <p
                    style={{
                      color: "white",
                      alignItems: "center",
                      marginTop: "3px",
                    }}
                  >
                    x
                  </p>
                </div>
              </div>
            ) : (
              ""
            )}
            <div>
              {currentData.length === 0 ? (
                null
              ) : (
                <nav aria-label="...">
                  <ul className="pagination">
                    <li className={`page-item ${currentPage === 1 ? "disabled" : ""}`}>
                      <button className="page-link" onClick={() => handlePageChange(currentPage - 1)}>Previous</button>
                    </li>
                    {[...Array(totalPages)].map((_, index) => (
                      <li key={index} className={`page-item ${currentPage === index + 1 ? "active" : ""}`}>
                        <button className="page-link" onClick={() => handlePageChange(index + 1)}>{index + 1}</button>
                      </li>
                    ))}
                    <li className={`page-item ${currentPage === totalPages ? "disabled" : ""}`}>
                      <button className="page-link" onClick={() => handlePageChange(currentPage + 1)}>Next</button>
                    </li>
                  </ul>
                </nav>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminUser;
