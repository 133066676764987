import React, { useState, useEffect } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import {
  // OverlayTrigger,
  // Tooltip,
  Dropdown,
  NavLink,
  Accordion,
  // Card,
} from "react-bootstrap";
import Calculator from "../../layouts/Calculator";
import $ from "jquery";
import "magnific-popup";
import axios from "axios";
import video1 from "../../../../src/assets/video/demo.mp4";
import BASE_URL from "../../baseUrl/baseUrl";
import Modal from "react-modal";
import bbqarea from "../../../assets/img/bbqarea.png";
import bathoom from "../../../assets/img/bathoom.png";
import Jacuzzi from "../../../assets/img/Jacuzzi.png";
import Sauna from "../../../assets/img/Sauna.png";
import bedroom from "../../../assets/img/bedroom.png";
import childrenplayground from "../../../assets/img/childrenplayground.jpg";
import communityhall from "../../../assets/img/communityhall.png";
import gameroom from "../../../assets/img/gameroom.png";
import guestparking from "../../../assets/img/guestparking.png";
import guestroom from "../../../assets/img/guestroom.png";
import gym from "../../../assets/img/gym.png";
import laundry from "../../../assets/img/laundry.png";
import library from "../../../assets/img/library.png";
import maidroom from "../../../assets/img/maidroom.png";
import meetingroom from "../../../assets/img/meetingroom.png";
import minimart from "../../../assets/img/minimart.jpg";
import parking from "../../../assets/img/parking.png";
import partyhall from "../../../assets/img/partyhall.png";
import swimingpool from "../../../assets/img/swimingpool.png";
import tennis from "../../../assets/img/tennis.png";
import diningroom from "../../../assets/img/diningroom.png";
import livingroom from "../../../assets/img/livingroom.png";
import Kitchens from "../../../assets/img/kitchens.png";
import ocean from "../../../assets/img/ocean.png";
import outdoorbath from "../../../assets/img/outdoorbath.png";
import elevator from "../../../assets/img/elevator.png";
import heater from "../../../assets/img/heater.png";
import fans from "../../../assets/img/air.png";
import solarPower from "../../../assets/img/solar.png";
import seaview from "../../../assets/img/seaview.png";
import hotwater from "../../../assets/img/hotwater.png";
import Slider from "react-slick";
import { Image } from "antd";
import penthouse from "../../../assets/img/penthouse.png";
import Electricity from "../../../assets/img/electricity.png";
import { MdOutlineContentCopy } from "react-icons/md";
import maintainanceFeeicon from "../../../assets/img/maintanencefee.jpg";
import { MdOutlineAttachMoney } from "react-icons/md";
const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    padding: "30px",
    width: "400px",
    transform: "translate(-50%, -50%)",
  },
};
// Gallery

// const gallerytip = <Tooltip>Gallery</Tooltip>;
// const bedstip = <Tooltip>Beds</Tooltip>;
// const bathstip = <Tooltip>Bathrooms</Tooltip>;
// const areatip = <Tooltip>Square Feet</Tooltip>;

const Listingwrapper = ({ setSeo }) => {
  const navigate = useNavigate();
  const [state, setState] = useState({
    Location: "location",
    BasicInformation: "BasicInformation",
    Details: "Details",
    Features: "Features",
    Gallery: "Gallery",
    Agent: null,
  });
  const [num, setNum] = useState(false);
  const [Callagent, setCallagent] = useState(false);
  const [featureList, setFeatureList] = useState([]);
  const [featureListData, setFeatureListData] = useState([
    { name: "Sea view", value: "seaview", image: seaview },
    { name: "Bathrooms", value: "bathrooms", image: bathoom },
    { name: "Dining Room", value: "dining", image: diningroom },
    { name: "Parking", value: "parking", image: parking },
    { name: "Guest room", value: "guestRoom", image: guestroom },
    { name: "Meeting room", value: "meetingRoom", image: meetingroom },
    { name: "Elevator", value: "outdoorBath", image: elevator },
    { name: "Library", value: "Library", image: library },
    { name: "heater", value: "heater", image: heater },

    { name: "Beds", value: "beds", image: bedroom },
    { name: "Living Room", value: "living", image: livingroom },
    { name: "KITCHEN", value: "Kitchens", image: Kitchens },
    { name: "Maid Room", value: "maidRoom", image: maidroom },
    { name: "Game Room", value: "gameRoom", image: gameroom },
    { name: "Laundry Room", value: "laundryRoom", image: laundry },
    { name: "BBQ Area", value: "BBQArea", image: bbqarea },
    // { name: "Community Hall", value: "CommunityHall", image: communityhall },
    // { name: "Swimming Pool", value: "SwimmingPool", image: swimingpool },
    // { name: "Party Hall", value: "PartyHall", image: partyhall },
    // { name: "Guest Parking", value: "GuestParking", image: guestparking },
    // { name: "Gym", value: "Gym", image: gym },
    // { name: "Mini Mart", value: "MiniMart", image: minimart },
    // { name: "OceanFront", value: "oceanFront", image: ocean },
    { name: "Aircondition", value: "Fans", image: fans },
    { name: "solar Power", value: "solarPower", image: solarPower },
    // { name: "Pent House", value: "penthouse", image: penthouse },
    // { name: "outdoor Bath", value: "outdoorBath", image: outdoorbath },
    // {
    //   name: "Children Playground",
    //   value: "playGround",
    //   image: childrenplayground,
    // },
    // { name: "Tennis Court", value: "tennis_court", image: tennis },
    // { name: "Jacuzzi", value: "jacuzzi", image: Jacuzzi },
    // { name: "Sauna", value: "sauna", image: Sauna },

    // { name: "Jacuzzi", value: "jacuzzi", image: Jacuzzi },
    // { name: "Sauna", value: "sauna", image: Sauna },
  ]);
  const [listing, setListing] = useState([]);
  const [Location, setLocation] = useState();
  const [showmore, setShowMore] = useState(false);
  const [latitude, setlatitude] = useState(false);
  const [longitude, setlongitude] = useState(false);
  const [map, setmap] = useState(false);
  const [detailid, setDetailid] = useState(false);
  const [loader, setLoader] = useState(false);
  const [True, setTrue] = useState();
  const [video, setvideo] = useState();
  const { id } = useParams();
  const [Test, setTest] = useState(true); // Initialize the Test state to true

  // Function to check if the URL is a Google Maps URL
  const isGoogleMapsURL = (url) => {
    const googleMapsPattern =
      /^https:\/\/(www\.)?google\.(com|co\.\w{2})\/maps/;
    const isValid = googleMapsPattern.test(url);
    setTest(isValid); // Update Test state
    return isValid;
  };
  useEffect(() => {
    if (Location) {
      isGoogleMapsURL(Location);
    }
  }, [Location]);
  // console.log(id);
  useEffect(() => {
    if (detailid) {
      getdatabyid(detailid);
    } else {
      getdatabyid(id);
    }
  }, [id, detailid]);
  const getdatabyid = async (id) => {
    console.log(id);
    setDetailid(id);
    await axios
      .get(`${BASE_URL}/submitlisting/submit/${id}`)
      .then((res) => {
        // console.log(res?.data?.result?.BasicInformation?.videofile);
        setLocation(res?.data?.result?.Location?.embedCode);
        setlatitude(res.data.result.Location.latitude);
        setlongitude(res.data.result.Location.longitude);
        setvideo(res.data.result.BasicInformation.video);
        setmap(true);
        setSeo(res.data.result.SEO);
        // setLocation(res?.data?.result)
      })
      .catch((err) => {});
  };
  // Split the link to extract the video identifier
  const videoId = state.BasicInformation?.video
    ? state.BasicInformation.video.split("?")[0].split("/").pop()
    : "";
  // console.log(videoId)
  const getData = async () => {
    const response = await fetch(`${BASE_URL}/submitlisting/get-properties`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        mode: "no-cors",
      },
    });
    const data = await response.json();
    const find = data.result.find((res) => {
      if (detailid) {
        return res._id === detailid;
      } else {
        return res._id === id;
      }
    });

    console.log(find);
    setState(find);

    const features = await fetch(`${BASE_URL}/admin/get-features`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        mode: "no-cors",
      },
    });
    const featureList = await features.json();
    // console.log(featureList);
    setFeatureList(featureList.result);
    setLoader(true);
  };
  useEffect(() => {
    getData();
    axios.get(`${BASE_URL}/submitlisting/lastsubmit`).then((res) => {
      setListing(res.data.result);
    });
  }, [detailid]);

  function popup() {
    var items = state?.Gallery?.picture?.map((name) => {
      // console.log(state?.Gallery?.picture);
      return {
        src: `${name}`,
      };
    });
    $(".gallery-thumb").magnificPopup({
      type: "image",
      gallery: {
        enabled: true,
      },
      tCounter: '<span class="mfp-counter">%curr% of %total%</span>', // markup of counter
      items: items,
    });
    // console.log(items);
  }
  if (!loader) return;
  const myArray = state.Features.map((item) => {
    return item._id;
  });
  // console.log(myArray);
  const dynamicImagePath = state?.Agent?.agentthumbnail;
  // console.log(state?.Details?.near);
  // const imageUrl =
  // state?.Details?.near?.find(nearItem => nearItem?.neartype?.name === "Top School")
  //   ? `${process.env.PUBLIC_URL}/assets/img/school.png`
  //   : state?.Details?.near?.find(nearItem => nearItem?.neartype?.name === "Hospital")
  //   ? `${process.env.PUBLIC_URL}/assets/img/DOWNTOWN_ICON.png`
  //   : state?.Details?.near?.find(nearItem => nearItem?.neartype?.name === "University")
  //   ? `${process.env.PUBLIC_URL}/assets/img/university.png`
  //   : state?.Details?.near?.find(nearItem => nearItem?.neartype?.name === "Transportation")
  //   ? `${process.env.PUBLIC_URL}/assets/img/transport.png`
  //   : state?.Details?.near?.find(nearItem => nearItem?.neartype?.name === "Shopping Mall")
  //   ? `${process.env.PUBLIC_URL}/assets/img/shopping.png`
  //   : state?.Details?.near?.find(nearItem => nearItem?.neartype?.name === "Down Town")
  //   ? `${process.env.PUBLIC_URL}/assets/img/DOWNTOWN_ICON.png`
  //   : state?.Details?.near?.find(nearItem => nearItem?.neartype?.name === "Airport")
  //   ? `${process.env.PUBLIC_URL}/assets/img/Airport.png`
  //   : `${process.env.PUBLIC_URL}/assets/img/default.png`;

  const toggleDetails = () => {
    setNum((prevState) => !prevState);
  };
  const CallAgent = () => {
    setCallagent((prevState) => !prevState);
  };
  const FloorImagesettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 1500,
    nextArrow: false,
    prevArrow: false,
    responsive: [
      {
        breakpoint: 1500,
        settings: {
          speed: 500,
          slidesToShow: 2,
          slidesToScroll: 1,
          autoplay: true,
          autoplaySpeed: 1500,
          infinite: true,
          dots: false,
          nextArrow: false,
          prevArrow: false,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          speed: 500,
          slidesToShow: 2,
          slidesToScroll: 1,
          autoplay: true,
          autoplaySpeed: 1500,
          infinite: true,
          dots: false,
          nextArrow: false,
          prevArrow: false,
        },
      },
      {
        breakpoint: 992,
        settings: {
          speed: 500,
          slidesToShow: 2,
          slidesToScroll: 1,
          autoplay: true,
          autoplaySpeed: 1500,
          infinite: true,
          dots: false,
          nextArrow: false,
          prevArrow: false,
        },
      },
      {
        breakpoint: 850,
        settings: {
          speed: 500,
          slidesToShow: 1,
          slidesToScroll: 1,
          autoplay: true,
          autoplaySpeed: 1500,
          nextArrow: false,
          prevArrow: false,
        },
      },
    ],
  };
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 1500,
    nextArrow: false,
    prevArrow: false,
    responsive: [
      {
        breakpoint: 1500,
        settings: {
          speed: 500,
          slidesToShow: 2,
          slidesToScroll: 1,
          autoplay: true,
          autoplaySpeed: 1500,
          infinite: true,
          dots: false,
          nextArrow: false,
          prevArrow: false,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          speed: 500,
          slidesToShow: 2,
          slidesToScroll: 1,
          autoplay: true,
          autoplaySpeed: 1500,
          infinite: true,
          dots: false,
          nextArrow: false,
          prevArrow: false,
        },
      },
      {
        breakpoint: 992,
        settings: {
          speed: 500,
          slidesToShow: 2,
          slidesToScroll: 1,
          autoplay: true,
          autoplaySpeed: 1500,
          infinite: true,
          dots: false,
          nextArrow: false,
          prevArrow: false,
        },
      },
      {
        breakpoint: 850,
        settings: {
          speed: 500,
          slidesToShow: 1,
          slidesToScroll: 1,
          autoplay: true,
          autoplaySpeed: 1500,
          nextArrow: false,
          prevArrow: false,
        },
      },
    ],
  };
  let subtitle;
  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    subtitle.style.color = "#f00";
  }
  const copyToClipboard = () => {
    const telNumber = state?.Agent?.agentTel || ""; // Fallback to an empty string if undefined
    navigator.clipboard
      .writeText(telNumber)
      .then(() => {
        alert("Telephone number copied to clipboard!"); // Optional: Alert to indicate the text was copied
      })
      .catch((err) => {
        console.error("Failed to copy text: ", err);
      });
  };
  const formatContactId = (id) => {
    // Agar id null ya undefined hai, toh empty string return karo
    if (!id) return "";

    // Yeh function check karta hai ke id sirf numbers par mushtamil hai
    const isNumeric = /^\d+$/.test(id);

    // Agar id sirf numbers par mushtamil hai
    if (isNumeric) {
      // return id.length > 10 ? "+" + id.slice(0, 10) + "..." : "+" + id;
      return "+" + id;
    }

    // Agar id mein numbers ke ilawa kuch aur bhi ho, toh id bina "+" ke return kare
    return id;
  };

  return (
    <div className="section listing-wrapper" style={{ marginBottom: "10%" }}>
      <div className="container">
        <div className="row">
          {/* Listings Start */}
          <div className="col-lg-8">
            {/* Content Start */}
            <div className="listing-content">
              <h4>Property Overview</h4>
              <p>{state?.BasicInformation?.description}</p>
              {state?.Gallery?.picture && state.Gallery.picture.length > 0 ? (
                state.Gallery.picture.length > 2 ? (
                  <div
                    className="row"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      marginLeft: "20px",
                      height: "260px",
                    }}
                  >
                    <Slider {...settings}>
                      {state.Gallery.picture.map((item, i) => (
                        <div key={i} className="col-md-4 mb-3">
                          <button
                            className="gallery-thumb"
                            style={{ border: "none", background: "none" }}
                          >
                            <Image
                              width={200}
                              height={200}
                              src={`${item}`}
                              style={{ width: "300px", height: "200px" }}
                            />
                          </button>
                        </div>
                      ))}
                    </Slider>
                  </div>
                ) : (
                  <div className="row">
                    {state.Gallery.picture.map((item, i) => (
                      <div key={i} className="col-md-4 mb-3">
                        <button
                          className="gallery-thumb"
                          style={{
                            border: "none",
                            background: "none",
                            width: "100%",
                          }}
                        >
                          <Image
                            width={""}
                            height={200}
                            src={`${item}`}
                            style={{ height: "200px" }}
                          />
                        </button>
                      </div>
                    ))}
                  </div>
                )
              ) : (
                <p className="text-center">No Property Images</p>
              )}
              <div>
                {state?.Floorimage && state.Floorimage.length > 0 ? (
                  <h4>Floor Images</h4>
                ) : (
                  ""
                )}
                {state?.Floorimage && state.Floorimage.length > 0 ? (
                  state.Floorimage.length > 2 ? (
                    <div
                      className="row"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        marginLeft: "20px",
                        height: "260px",
                      }}
                    >
                      <Slider {...FloorImagesettings}>
                        {state.Floorimage.map((item, i) => (
                          <div key={i} className="col-md-4 mb-3">
                            <button
                              className="gallery-thumb"
                              style={{ border: "none", background: "none" }}
                            >
                              <Image
                                width={200}
                                height={200}
                                src={`${item}`}
                                style={{ height: "200px" }}
                              />
                            </button>
                          </div>
                        ))}
                      </Slider>
                    </div>
                  ) : (
                    <div className="row">
                      {state.Floorimage.map((item, i) => (
                        <div key={i} className="col-md-4 mb-3 me-2">
                          <button
                            className="gallery-thumb"
                            style={{
                              border: "none",
                              background: "none",
                              width: "100%",
                            }}
                          >
                            <Image
                              width={""}
                              height={200}
                              src={`${item}`}
                              style={{ height: "200px" }}
                            />
                          </button>
                        </div>
                      ))}
                    </div>
                  )
                ) : (
                  ""
                )}
              </div>
            </div>
            <div style={{ height: "8%", padding: "5% 0", textAlign: "center" }}>
              <h4 style={{ marginBottom: "2%" }}>Direction</h4>
              {/* {console.log(Location)} */}
              {map && Test ? (
                <iframe
                  width="100%"
                  height="95%"
                  title="direction"
                  src={Location}
                ></iframe>
              ) : (
                <p>Invalid URL: Only Google Maps URLs are allowed.</p>
              )}
            </div>
            {/* Content End */}
            {/* Price Range In the area Start */}
            <div className="section">
              <div className="acr-area-price">
                <span style={{ left: "30%" }}>
                  {state.BasicInformation?.listingType === "Rent" ? (
                    <>
                      {Number(
                        state?.BasicInformation?.rentalPrice
                      ).toLocaleString()}
                      {state?.BasicInformation?.currency}
                    </>
                  ) : (
                    <>
                      {Number(state?.BasicInformation?.price).toLocaleString()}
                      {state?.BasicInformation?.currency}
                    </>
                  )}
                </span>
                <div className="progress">
                  <div
                    className="progress-bar"
                    role="progressbar"
                    style={{ width: "50%" }}
                    aria-valuenow={50}
                    aria-valuemin={0}
                    aria-valuemax={100}
                  />
                </div>
                <div className="acr-area-price-wrapper">
                  <div className="acr-area-price-min">
                    <h5>
                      {state?.BasicInformation?.minRangecurrency
                        ? state?.BasicInformation?.minRangecurrency
                        : "$"}
                      {state?.BasicInformation?.minRange
                        ? Number(
                            state?.BasicInformation?.minRange
                          ).toLocaleString()
                        : "100$"}
                    </h5>
                    <span>Lowest</span>
                  </div>
                  <h5>Price range in the area</h5>
                  <div className="acr-area-price-max">
                    <h5>
                      {state?.BasicInformation?.maxRangecurrency
                        ? state?.BasicInformation?.maxRangecurrency
                        : "$"}
                      {state?.BasicInformation?.maxRange
                        ? Number(
                            state?.BasicInformation?.maxRange
                          ).toLocaleString()
                        : "100$"}
                    </h5>
                    <span>Highest</span>
                  </div>
                </div>
              </div>
            </div>
            {/* Price Range In the area End */}
            <div className="section section-padding pt-0 acr-listing-features">
              <h4>Features</h4>
              <div className="row">
                <div className="col-lg-6 col-md-6">
                  <div className="listing-feature-wrapper">
                    <div className="listing-feature">
                      <i className="flaticon-key" />
                      <h6 className="listing-feature-label">Property Id</h6>
                      <span className="listing-feature-value">
                        {state?.Details?.id || "--"}
                      </span>
                    </div>
                    <div className="listing-feature">
                      <i className="flaticon-garage" />
                      <h6 className="listing-feature-label">Parking</h6>
                      <span className="listing-feature-value">
                        {state?.Details?.parking || "--"}
                      </span>
                    </div>
                    <div className="listing-feature">
                      <i className="flaticon-garage" />
                      <h6 className="listing-feature-label">Building Floor</h6>
                      <span className="listing-feature-value">
                        {state?.Details?.story || "--"}
                      </span>
                    </div>
                    <div className="listing-feature">
                      <i className="flaticon-history" />
                      <h6 className="listing-feature-label">Year Built</h6>
                      <span className="listing-feature-value">
                        {state?.Details?.built || "--"}
                      </span>
                    </div>
                    <div className="listing-feature">
                      <i className="flaticon-picture" />
                      <h6 className="listing-feature-label">Propery Type</h6>
                      <span className="listing-feature-value">
                        {state?.BasicInformation?.type || "--"}
                      </span>
                    </div>
                    <div className="listing-feature">
                      <img
                        src={maintainanceFeeicon}
                        className="h-[50px] w-[50px] me-2"
                      />
                      <h6 className="listing-feature-label">
                        Maintainance Fee
                      </h6>
                      <span className="listing-feature-value">
                        {state?.BasicInformation?.maintainanceFeecurrency ||
                          "$"}
                        {state?.BasicInformation?.maintainanceFee || "--"}
                      </span>
                    </div>
                    {
                      state?.BasicInformation?.listingType == "Rent" && (
                        <div className="listing-feature">
                      <i className="flaticon-new" />
                      <h6 className="listing-feature-label">Condition</h6>
                      <span className="listing-feature-value">
                        {state?.Details?.condition == "Select Condition"
                          ? "---"
                          : state?.Details?.condition
                          ? state?.Details?.condition
                          : "--"}
                      </span>
                    </div>
                      )
                    }
                  </div>
                </div>
                <div className="col-lg-6 col-md-6">
                  <div className="listing-feature-wrapper">
                    <div className="listing-feature">
                      <i className="flaticon-ruler" />
                      <h6 className="listing-feature-label">Property Size</h6>
                      <span className="listing-feature-value">
                        {`${state?.BasicInformation?.space} sqft` || "--"}
                      </span>
                    </div>
                    <div className="listing-feature">
                      <i className="flaticon-ruler" />
                      <h6 className="listing-feature-label">Lot Size</h6>
                      <span className="listing-feature-value">
                        {state?.Details?.lotsize || "--"}
                      </span>
                    </div>
                    <div className="listing-feature">
                      <i className="flaticon-ruler" />
                      <h6 className="listing-feature-label">View</h6>
                      <span className="listing-feature-value">
                        {state?.Details?.view == "Select View"
                          ? "--"
                          : state?.Details?.view
                          ? state?.Details?.view
                          : "--"}
                      </span>
                    </div>
                    <div className="listing-feature">
                      <i className="flaticon-bathroom" />
                      <h6 className="listing-feature-label">Bathrooms</h6>
                      <span className="listing-feature-value">
                        {state?.Details?.bathrooms || "--"}
                      </span>
                    </div>
                    <div className="listing-feature">
                      <i className="flaticon-pillow" />
                      <h6 className="listing-feature-label">Bed Rooms</h6>
                      <span className="listing-feature-value">
                        {state?.Details?.beds || "--"}
                      </span>
                    </div>
                    {state?.BasicInformation?.listingType == "Rent" && (
                      <div className="listing-feature">
                        <div className="flex">
                          <img
                            src={Electricity}
                            alt=""
                            className="me-3"
                            style={{ width: "50px", height: "50px" }}
                          />
                          <h6 className="listing-feature-label mb-1 mt-3">
                            Paid Electricity
                          </h6>
                        </div>
                        <span className="listing-feature-value">
                          {state.PropertyAmenity.oceanFront == "true"
                            ? "Yes"
                            : state.PropertyAmenity.oceanFront == "false"
                            ? "No"
                            : "--"}
                        </span>
                      </div>
                    )}

                    {state?.BasicInformation?.listingType == "Rent" && (
                      <div className="listing-feature">
                        <div className="flex">
                          <img
                            src={hotwater}
                            alt=""
                            className="me-3"
                            style={{ width: "50px", height: "50px" }}
                          />
                          <h6 className="listing-feature-label mb-1 mt-3">
                            Paid Water
                          </h6>
                        </div>
                        <span className="listing-feature-value">
                          {state.PropertyAmenity.hotWater == "true"
                            ? "Yes"
                            : state.PropertyAmenity.hotWater == "false"
                            ? "No"
                            : "--"}
                        </span>
                      </div>
                    )}
                   {
                    state?.BasicInformation?.listingType == "Sell" && (
                      <div className="listing-feature">
                      <i className="flaticon-new" />
                      <h6 className="listing-feature-label">Condition</h6>
                      <span className="listing-feature-value">
                        {state?.Details?.condition == "Select Condition"
                          ? "---"
                          : state?.Details?.condition
                          ? state?.Details?.condition
                          : "--"}
                      </span>
                    </div>
                    )
                   }
                  </div>
                </div>
              </div>

              <div className={showmore ? "d-block" : `hidden-listing-features`}>
                <div className="row">
                  {/* <div className="col-lg-6 col-md-6">
                    {featureList
                      .slice(0, featureList.length / 2 + 1)
                      .map((item, key) => (
                        <div key={key} className="listing-feature">
                          <i>
                            <img
                              src={`${item.icon}`}
                              alt="FeatureIcon"
                            />
                          </i>{" "}
                          <h6 className="listing-feature-label newClass">
                            {item.name}
                          </h6>
                          {state.Features.map((item) =>
                            item.includes(item._id) ? (
                              <span>Yes</span>
                            ) : (
                              <span>No</span>
                            )
                          )}                         
                          
                          {console.log("object",item?.name,Object.keys(state?.PropertyAmenity),item?.name.includes(state?.PropertyAmenity))}

                          {Object.keys(state?.PropertyAmenity).includes(item?.name) ? "yes" : "no"}
                        </div>
                      ))}
                  </div> */}

                  <div className="col-lg-6 col-md-6 ps-0">
                    {featureListData.slice(0, 9).map((item, key) => (
                      <div key={key} className="listing-feature">
                        <i>
                          <img
                            // src="https://real-estate-react.nyc3.digitaloceanspaces.com/parking.png"
                            src={
                              item.image
                                ? item.image
                                : "https://real-estate-react.nyc3.digitaloceanspaces.com/parking.png"
                            }
                            alt="a"
                          />
                        </i>{" "}
                        <h6 className="listing-feature-label newClass">
                          {item.name}
                        </h6>
                        {/* {state.Features.map((item) =>
                            item.includes(item._id) ? (
                              <span>Yes</span>
                            ) : (
                              <span>No</span>
                            )
                          )} */}
                        {/* {myArray.includes(item._id) ? "yes" : "no"} */}
                        {/* {console.log(
                            "object",
                            item?.name,
                            Object.keys(state?.PropertyAmenity),
                            item?.name.includes(state?.PropertyAmenity)
                          )} */}
                        {/* {Object.keys(state?.PropertyAmenity).includes(
                            item?.name
                          )
                            ? "yes"
                            : "no"} */}
                        {state?.Details[item.value] === "true"
                          ? "Yes"
                          : state?.Details[item.value] === "false"
                          ? "No"
                          : state?.PropertyAmenity[item.value] === "true"
                          ? "Yes"
                          : state?.PropertyAmenity[item.value] === "false"
                          ? "No"
                          : state?.Details[item.value] || "--"}
                      </div>
                    ))}
                  </div>
                  <div className="col-lg-6 col-md-6">
                    {/* {featureList
                      .slice(featureList.length / 2 + 1)
                      .map((item, key) => (
                        <div key={key} className="listing-feature">
                          <img
                            style={{ width: "50px", height: "50px" }}
                            src={item.icon}
                          />
                          <h6
                            style={{ marginLeft: "20px" }}
                            className="listing-feature-label"
                          >
                            {item.name}
                          </h6>
                          {Object.keys(state?.PropertyAmenity).includes(
                            item?.name
                          )
                            ? "yes"
                            : "no"}
                        </div>
                      ))} */}
                    {featureListData.slice(9).map((item, key) => (
                      <div key={key} className="listing-feature">
                        {item.image ? (
                          <img
                            style={{ width: "50px", height: "50px" }}
                            src={item.image}
                          />
                        ) : (
                          ""
                        )}
                        <h6
                          style={{ marginLeft: "20px" }}
                          className="listing-feature-label"
                        >
                          {item.name}
                        </h6>
                        {/* {Object.keys(state?.PropertyAmenity).includes(
                            item?.name
                          )
                            ? "yes"
                            : "no"} */}
                        {/* {console.log("first", state?.Details[item.value])} */}
                        {state?.Details[item.value] === "true"
                          ? "Yes"
                          : state?.Details[item.value] === "false"
                          ? "No"
                          : state?.PropertyAmenity[item.value] === "true"
                          ? "Yes"
                          : state?.PropertyAmenity[item.value] === "false"
                          ? "No"
                          : state?.Details[item.value] || "--"}

                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <button
                type="button"
                className="load-more-features btn-custom-2 light-grey btn-block"
                onClick={() => setShowMore(!showmore)}
              >
                {showmore ? "Few" : "More"}
              </button>
            </div>
            {/* <div className="section pt-0 acr-listing-nearby">
              <h4>What's Nearby</h4>
              {state?.Details?.near && state.Details.near.map((item, key) => (
                <div className="listing-nearby-item" key={key}>
                  <div className="flex justify-between">
                    <h5>Type</h5>
                    <h5>Location</h5>
                    <h5>Distance</h5>
                  </div>
                  <div className="container">
                    <div className="row align-items-center">
                      <div className="col-md-4 col-sm-12 flex justify-between">
                        <div className="flex">
                          <img
                            src={`${item?.neartype?.name === "Top School"
                              ? `${process.env.PUBLIC_URL}/assets/img/school.png`
                              : item?.neartype?.name === "University"
                                ? `${process.env.PUBLIC_URL}/assets/img/university.png`
                                : item?.neartype?.name === "Hospital"
                                  ? `${process.env.PUBLIC_URL}/assets/img/HOSPITAL__ICON.png`
                                  : item?.neartype?.name === "Shopping Mall"
                                    ? `${process.env.PUBLIC_URL}/assets/img/shopping.png`
                                    : item?.neartype?.name === "Transportation"
                                      ? `${process.env.PUBLIC_URL}/assets/img/transport.png`
                                      : item?.neartype?.name === "Down Town"
                                        ? `${process.env.PUBLIC_URL}/assets/img/DOWNTOWN_ICON.png`
                                        : item?.neartype?.name === "Airport"
                                          ? `${process.env.PUBLIC_URL}/assets/img/Airport.png`
                                          : ""
                              }`}
                            alt="TypeIcon"
                            className="h-6 w-6"
                          />
                          <div  style={{ color: `${item.neartype.color}` }}>
                            {item.neartype.name}
                          </div>
                        </div>
                      </div>
                      <div className="col-md-5 col-sm-12  flex justify-center">
                        <div>
                          {item.name}
                        </div>
                      </div>
                      <div className="col-md-3 col-sm-12  flex justify-center">
                        <div>
                          ({item.distance} km)
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div> */}

            <div className="section pt-0 acr-listing-nearby">
              <h4>What's Nearby</h4>
              <div className="listing-nearby-header text-2xl">
                <div className="listing-nearby-column">Type</div>
                <div className="listing-nearby-column">Location</div>
                <div className="listing-nearby-column">Distance</div>
              </div>
              {state?.Details?.near &&
                state.Details.near.map((item, key) => (
                  <div className="listing-nearby-item" key={key}>
                    <div className="listing-nearby-content">
                      <div className="listing-nearby-column">
                        <div className="flex">
                          <img
                            src={`${
                              item?.neartype?.name === "Top School"
                                ? `${process.env.PUBLIC_URL}/assets/img/school.png`
                                : item?.neartype?.name === "University"
                                ? `${process.env.PUBLIC_URL}/assets/img/university.png`
                                : item?.neartype?.name === "Hospital"
                                ? `${process.env.PUBLIC_URL}/assets/img/HOSPITAL__ICON.png`
                                : item?.neartype?.name === "Shopping Mall"
                                ? `${process.env.PUBLIC_URL}/assets/img/shopping.png`
                                : item?.neartype?.name === "Transportation"
                                ? `${process.env.PUBLIC_URL}/assets/img/transport.png`
                                : item?.neartype?.name === "Down Town"
                                ? `${process.env.PUBLIC_URL}/assets/img/DOWNTOWN_ICON.png`
                                : item?.neartype?.name === "Airport"
                                ? `${process.env.PUBLIC_URL}/assets/img/Airport.png`
                                : ""
                            }`}
                            alt="TypeIcon"
                            className="h-6 w-6"
                          />
                          <div style={{ color: `${item.neartype.color}` }}>
                            {item.neartype.name}
                          </div>
                        </div>
                      </div>
                      <div className="listing-nearby-column">{item.name}</div>
                      <div className="listing-nearby-column">
                        ({item.distance} km)
                      </div>
                    </div>
                  </div>
                ))}
            </div>

            <div className="section">
              {state?.BasicInformation?.video ? <h4>Property Video</h4> : ""}
              {state?.BasicInformation?.video ? (
                <div className="videodiv">
                  <div className="w-[100%] mb-4" autoPlay>
                    <iframe
                      width="100%"
                      height="315"
                      src={`https://www.youtube.com/embed/${videoId}`}
                      title="YouTube video player"
                      frameborder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                      allowfullscreen
                    ></iframe>
                  </div>
                </div>
              ) : (
                ""
              )}

              {state.BasicInformation.videofile ? (
                <div className="videodiv">
                  <div className="w-[100%] " autoPlay>
                    <video controls width="100%">
                      <source
                        src={
                          state.BasicInformation.videofile?.includes("uploads/")
                            ? `${BASE_URL}/${state.BasicInformation.videofile}`
                            : state.BasicInformation.videofile
                        }
                        type="video/mp4"
                      />
                    </video>
                  </div>
                </div>
              ) : null}
            </div>
            {/* <div className="section pt-0 acr-listing-history">
              <h4>Property History</h4>
              <Accordion defaultActiveKey="0" className="with-gap">
                <Accordion.Item eventKey="1">
                  <Accordion.Header>1979 - 1999</Accordion.Header>
                  <Accordion.Body className="collapseparent">
                    <div className="row">
                      <div className="col-sm-4">
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            "/assets/img/listing-single/history-1.jpg"
                          }
                          alt="property history"
                        />
                      </div>
                      <div className="col-sm-8">
                        <h5>The Beginning</h5>
                        Anim pariatur cliche reprehenderit, enim eiusmod high
                        life accusamus terry richardson ad squid. 3 wolf moon
                        officia aute, non cupidatat skateboard dolor brunch.
                        Food truck quinoa nesciunt laborum eiusmod. Brunch 3
                        wolf moon tempor, sunt aliqua put a bird on it squid
                        single-origin coffee nulla assumenda shoreditch et.
                        Nihil anim keffiyeh helvetica, craft beer labore wes
                        anderson cred nesciunt sapiente ea proident. Ad vegan
                        excepteur butcher vice lomo. Leggings occaecat craft
                        beer farm-to-table, raw denim aesthetic synth nesciunt
                        you probably haven't heard of them accusamus labore
                        sustainable VHS.
                      </div>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2">
                  <Accordion.Header>2000 - 2012</Accordion.Header>
                  <Accordion.Body className="collapseparent">
                    <div className="row">
                      <div className="col-sm-4">
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            "/assets/img/listing-single/history-2.jpg"
                          }
                          alt="property history"
                        />
                      </div>
                      <div className="col-sm-8">
                        <h5>The Rebuilding Phase</h5>
                        Anim pariatur cliche reprehenderit, enim eiusmod high
                        life accusamus terry richardson ad squid. 3 wolf moon
                        officia aute, non cupidatat skateboard dolor brunch.
                        Food truck quinoa nesciunt laborum eiusmod. Brunch 3
                        wolf moon tempor,
                      </div>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="3">
                  <Accordion.Header>2013 - Till date</Accordion.Header>
                  <Accordion.Body className="collapseparent">
                    <div className="row">
                      <div className="col-sm-4">
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            "/assets/img/listing-single/history-3.jpg"
                          }
                          alt="property history"
                        />
                      </div>
                      <div className="col-sm-8">
                        <h5>Modernization</h5>
                        Anim pariatur cliche reprehenderit, enim eiusmod high
                        life accusamus terry richardson ad squid. 3 wolf moon
                        officia aute, non cupidatat skateboard dolor brunch.
                        Food truck quinoa nesciunt laborum eiusmod. Brunch 3
                        wolf moon tempor,
                      </div>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </div> */}

            {/* Sahi haon  */}
            {/* <div
              style={{ position: "relative", top: "0vh" }}
              className="section md-top-0"
            >
              <h4>Schedule Link tour</h4>
              <form>
                <div className="row">
                  <div className="col-md-6 form-group">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Full Name"
                      name="fname"
                    />
                  </div>
                  <div className="col-md-6 form-group">
                    <input
                      type="email"
                      className="form-control"
                      placeholder="Email Address"
                      name="email"
                    />
                  </div>
                  <div className="col-md-6 form-group">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Phone Number"
                      name="phone"
                    />
                  </div>
                  <div className="col-md-6 form-group">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Date"
                      name="date"
                    />
                  </div>
                  <div className="col-md-12 form-group">
                    <textarea
                      className="form-control"
                      placeholder="Type your comment..."
                      name="comment"
                      rows={7}
                    />
                  </div>
                </div>
                <button
                  type="submit"
                  className="btn-custom primary"
                  name="button"
                >
                  Schedule Tour
                </button>
              </form>
            </div> */}

            {/* Pagination Start */}
            {/* <div className="section p-0 post-single-pagination-wrapper">
              <div className="post-single-pagination post-prev">
                <i className="fas fa-arrow-left" />
                <Link to="#" className="post-single-pagination-content">
                  <span>Prev Listing</span>
                  <h6>Theodore Lowe, Azusa New York 39531</h6>
                </Link>
              </div>
              <div className="post-single-pagination post-next">
                <Link to="#" className="post-single-pagination-content">
                  <span>Next Listing</span>
                  <h6>Cecilia Chapman, Mankato Mississippi 96522</h6>
                </Link>
                <i className="fas fa-arrow-right" />
              </div>
            </div> */}
            {/* Pagination End */}
            {/* Similar Start */}
            {/* Similar End */}
          </div>
          {/* Listings End */}

          {/* Sidebar Start */}
          <div className="col-lg-4">
            <div className="sidebar sticky-sidebar">
              <div className="sidebar-widget">
                <h5>Meet The Agent</h5>
                {/* Author Start */}
                <div className="media sidebar-author listing-agent">
                  {/* {console.log(process.env.PUBLIC_URL, dynamicImagePath)} */}
                  <Link to="#">
                    <img
                      src={`${process.env.PUBLIC_URL}${state?.Agent?.agentthumbnail}`}
                      alt="agent"
                    />
                  </Link>
                  <div className="media-body">
                    <h6>
                      {state?.Agent?.agentName}
                      <Link to="#"></Link>{" "}
                    </h6>
                    <span>Company Agent</span>
                  </div>
                  <div>
                    <button
                      type="button"
                      onClick={toggleDetails}
                      className="btn-custom primary light btn-block mt-2 mb-2"
                    >
                      {num ? (
                        <span>Hide Agent Details</span>
                      ) : (
                        <span>Show Agent Details</span>
                      )}
                    </button>
                    {num && (
                      <div>
                        {/* <h6 style={{ marginBottom: "10px" }}>Agent Info</h6>
                        <p style={{ marginBottom: "0px" }}>
                          Call Agent: &nbsp;
                          {state?.Agent?.agentTel?.length > 10
                            ? state.Agent.agentTel.slice(0, 10) + "..."
                            : state.Agent.agentTel}
                        </p> */}
                        <p style={{ marginBottom: "0px" }}>
                          LineID: &nbsp;
                          {formatContactId(state?.Agent?.Line_Id)}
                        </p>
                        <p style={{ marginBottom: "0px" }}>
                          WhatsApp: &nbsp;
                          {formatContactId(state?.Agent?.agentwhatapp)}
                        </p>
                        <p style={{ marginBottom: "0px" }}>
                          WeChatID: &nbsp;
                          {formatContactId(state?.Agent?.WeChatID)}
                        </p>
                        <p style={{ marginBottom: "0px" }}>
                          City: &nbsp;
                          {/* {state?.Agent?.agentCountry?.length > 10
                            ? state.Agent.agentCountry.slice(0, 10) + "..."
                            : state.Agent.agentCountry} */}
                          {state.Agent.agentCountry}
                        </p>
                        <p style={{ marginBottom: "0px" }}>
                          Province: &nbsp;
                          {/* {state?.Agent?.agentProvince?.length > 10
                            ? state.Agent.agentProvince.slice(0, 10) + "..."
                            : state.Agent.agentProvince} */}
                          {state.Agent.agentProvince}
                        </p>
                      </div>
                    )}
                  </div>
                  {state?.Agent?.agentTel && (
                    <button
                      type="button"
                      onClick={CallAgent}
                      className="btn-custom primary light btn-block mt-2 mb-2"
                    >
                      Call Agent
                    </button>
                  )}
                  <div className="text-left">
                    <p style={{ marginBottom: "0px", color: "#519fff" }}>
                      Area Service: &nbsp;
                      {/* {state?.Agent?.agentServices?.length > 10
                        ? state.Agent.agentServices.slice(0, 10) + "..."
                        : state.Agent.agentServices || "---"} */}
                        {state.Agent.agentServices}
                    </p>
                  </div>
                  {/* <Dropdown className="options-dropdown">
                    <Dropdown.Toggle as={NavLink}>
                      <i className="fas fa-ellipsis-v" />
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="dropdown-menu-right">
                      <ul>
                        <li>
                          {" "}
                          <Link to="tel:+123456789">
                            {" "}
                            <i className="fas fa-phone" /> Call Agent
                          </Link>{" "}
                        </li>
                        <li>
                          {" "}
                          <Link to="/listing-grid">
                            {" "}
                            <i className="fas fa-th-list" /> View Listings
                          </Link>{" "}
                        </li>
                        <li>
                          {" "}
                          <Link to="#">
                            {" "}
                            <i className="fas fa-star" /> Save Agent
                          </Link>{" "}
                        </li>
                      </ul>
                    </Dropdown.Menu>
                  </Dropdown> */}
                </div>
                {/* Author End */}
                {/* Contact Start */}
                {/* <form>
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Email Address"
                      name="email"
                    />
                  </div>
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Phone Number"
                      name="phone"
                    />
                  </div>
                  <div className="form-group">
                    <textarea
                      name="message"
                      rows={3}
                      placeholder="Enter your message"
                      className="form-control"
                    />
                  </div>
                  <button
                    type="submit"
                    className="btn-custom primary light btn-block"
                  >
                    Send Message
                  </button>
                </form> */}
                {/* Contact End */}
              </div>
              <div className="sidebar-widget">
                <h5>Recent Listings</h5>
                {/* Listing Start */}
                {listing.map((item, i) => (
                  <div key={i} className="listing listing-list">
                    <div className="listing-thumbnail">
                      <Link onClick={() => getdatabyid(item._id)}>
                        <img src={`${item.Gallery.file[0]}`} alt="listing" />
                      </Link>
                    </div>
                    <div className="listing-body">
                      <h6 className="listing-title">
                        {" "}
                        <Link
                          onClick={() => getdatabyid(item._id)}
                          title={item.BasicInformation.name}
                        >
                          {item.BasicInformation.name}
                        </Link>{" "}
                      </h6>
                      <span className="listing-price">
                        {item.BasicInformation.currency}
                        {item.BasicInformation.listingType == "Rent"
                          ? Number(
                              item.BasicInformation.rentalPrice
                            ).toLocaleString()
                          : Number(
                              item.BasicInformation.price
                            ).toLocaleString()}
                      </span>
                    </div>
                  </div>
                ))}
                {/* Listing End */}
              </div>
              <div className="sidebar-widget">
                <h5>Mortgage Calculator</h5>
                <Calculator />
              </div>
            </div>
          </div>
          {/* Sidebar End */}
        </div>
      </div>
      {Callagent && (
        <div>
          <Modal
            isOpen={CallAgent}
            onAfterOpen={afterOpenModal}
            onRequestClose={setCallagent}
            style={customStyles}
            contentLabel="Example Modal"
          >
            <div className="flex justify-between items-center">
              <h6 ref={(_subtitle) => (subtitle = _subtitle)}>Call Agent</h6>
              <button
                className="btn btn-primary"
                onClick={() => setCallagent(false)}
              >
                close
              </button>
            </div>
            <div className="flex justify-between item-center my-3">
              <p style={{ marginBottom: "0px" }}>
                Tel number: &nbsp;
                {formatContactId(state?.Agent?.agentTel)}
              </p>
              <p onClick={copyToClipboard} style={{ cursor: "pointer" }}>
                <MdOutlineContentCopy />
              </p>
            </div>
          </Modal>
        </div>
      )}
    </div>
  );
};

export default Listingwrapper;
