// import React from 'react'

// const AdminTopList = () => {
//   return (
//     <div>AdminTopList</div>
//   )
// }

// export default AdminTopList

import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import AdminHeader from "../layouts/AdminHeader";
import AdminSider from "../layouts/AdminSider";
import axios from "axios";
import BASE_URL from "../baseUrl/baseUrl";
import { useToast } from "@chakra-ui/react";
import { Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
export default function AdminTopList(props) {
  console.log(props);
  const [collapsed, setCollapsed] = useState(false);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [data, setData] = useState([]);
  const [flag, setFlag] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentPageList, setCurrentPageList] = useState(1);
  const [totalPage, settotalPage] = useState();
  const [showModal, setShowModal] = useState(false);
  const [searchItem, setsearchItem] = useState(false);
  const [PromptError, setPromptError] = useState(false);
  const [myList, setMyList] = useState(false);
  const [modalCurrentPage, setModalCurrentPage] = useState(1);
  const [modalTotalPage, setModalTotalPage] = useState();
  
  const navigate=useNavigate()
  //   const [bestDeal, setbestDeal] = useState([]);
  const toast = useToast();
  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
  };
  const limit = 10;
  useEffect(() => {
    if(currentPageList){
      getData(currentPageList);
      return
    }
  }, [props.type,currentPageList]);


  const getData = async (page) => {
    const response = await axios.get(
      `${BASE_URL}/admin/get-Allproperties/${props.type}?page=${page}&limit=${limit}`
    );
    if (response) {
      setFlag(true);
      console.log(response?.data?.pagination?.totalPages);
      settotalPage(response?.data?.pagination?.totalPages);
      setData(response?.data?.result);
      setMyList(response?.data?.result)
    }
  };
  // const itemsPerPage = 10;
  // const totalPages = Math.ceil(data.length / itemsPerPage);
  // const startIndex = (currentPage - 1) * itemsPerPage;
  // const currentData = data.slice(startIndex, startIndex + itemsPerPage);

  const handlePageChange = (pageNumber) => {
    // console.log(currentPage,pageNumber);
    setCurrentPageList(pageNumber);
  };

  const handleDeal = async (id) => {
    try {
      const response = await axios.post(`${BASE_URL}/admin/topList/${id}`);
      console.log("object", response);
      if (response) {
        const updatedData = data.map((property) =>
          property._id === id
            ? { ...property, isTopList: !property.isTopList }
            : property
        );
        setData(updatedData);
        if (
          response?.data?.message ===
          "The property has been designated as a top listing."
        ) {
          toast({
            title: "success",
            description: response?.data?.message,
            status: "success",
            duration: 2000,
            variant: "left-accent",
            position: "top-right",
            isClosable: true,
          });
        } else if (
          response?.data?.message ===
          "The property has been removed from the top listings designation."
        ) {
          toast({
            title: "Remove",
            description: response.data.message,
            status: "error",
            duration: 2000,
            variant: "left-accent",
            position: "top-right",
            isClosable: true,
          });
        } else {
          toast({
            title: "Error",
            description: "Title",
            status: "error",
            duration: 2000,
            variant: "left-accent",
            position: "top-right",
            isClosable: true,
          });
        }
      }
    } catch (error) {}
  };
  const handleDeal2 = async (id) => {
    try {
      const response = await axios.post(`${BASE_URL}/admin/topList/${id}`);
      console.log("object", response);
      if (response) {
        const updatedData = myList?.map((property) =>
          property._id === id
            ? { ...property, isTopList: !property.isTopList }
            : property
        );
        setData(updatedData);
        if (
          response?.data?.message ===
          "The property has been designated as a top listing."
        ) {
          handleCloseModal()
          toast({
            title: "success",
            description: response?.data?.message,
            status: "success",
            duration: 2000,
            variant: "left-accent",
            position: "top-right",
            isClosable: true,
          });
        } else if (
          response?.data?.message ===
          "The property has been removed from the top listings designation."
        ) {
          handleCloseModal()
          toast({
            title: "Remove",
            description: response.data.message,
            status: "error",
            duration: 2000,
            variant: "left-accent",
            position: "top-right",
            isClosable: true,
          });
        } else {
          handleCloseModal()
          toast({
            title: "Error",
            description: "Title",
            status: "error",
            duration: 2000,
            variant: "left-accent",
            position: "top-right",
            isClosable: true,
          });
        }
      }
    } catch (error) {}
  };
  const handleCloseModal = () => {
    setCurrentPageList(1)
    setsearchItem("");
    setMyList(null);
    setShowModal(false);
  };

  const handleOpenModal = () => {
    setCurrentPageList(1)
    handleModalPageChange(1)
    setShowModal(true);
  };
  const handleSearch = async (e) => {
    const search = e.target.value;
    if (!search) {
      setCurrentPageList(1);
      getData(1);  // Reload all data if the search field is empty
      setPromptError("");
      setMyList([]); // Clear search results
    } else {
      try {
        setPromptError("");
        const response = await axios.post(
          `${BASE_URL}/admin/searchProperty/${props.type}`,
          {
            prompt: search,
          }
        );
        if (response) {
          console.log(response);
          setMyList(response.data?.result);
          settotalPage(response?.data?.totalPage)
        }
      } catch (error) {}
    }
  };
  // const itemsPerPage = 10;
  // const totalPages = Math.ceil(myList && myList?.length / itemsPerPage);
  // const startIndex = (currentPageList - 1) * itemsPerPage;
  // const currentData =myList && myList?.slice(startIndex, startIndex + itemsPerPage);

  const handleModalPageChange = (pageNumber) => {
    setModalCurrentPage(pageNumber);
    
    if (searchItem) {
      handleSearch(prompt, pageNumber); // Pass both prompt and pageNumber
    } else {
      getData(pageNumber);  // Load all data if search prompt is empty
    }
  };
  return (
    <div>
      <Helmet>
        <title>Buy Home For Less | Admin Category Create</title>
        <meta name="description" content="#" />
      </Helmet>
      <AdminHeader collapsed={collapsed} toggleCollapsed={toggleCollapsed} />
      <Modal
        show={showModal}
        onHide={handleCloseModal}
        centered
        backdrop="true"
        keyboard={false}
        dialogClassName="custom-modal"
        size="md"
      >
        <Modal.Header closeButton>
          <Modal.Title className="dashboard-commission-modal">
            Property
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* {detail && ( */}
          <div className="container">
            <div className="row mt-4">
              <div className="col-lg-6 col-md-6 col-sm-12 d-flex justify-center items-center w-100 flex-col mb-3">
                <h6>Search Property: </h6>
                <input
                  type="text"
                  // value={commissionAmount}
                  // onChange={(e) => setCommissionAmount(e.target.value)}
                  style={{
                    border: "1px solid black",
                    borderRadius: "5px",
                    padding: "8px",
                    width: "100%",
                  }}
                  className="no-spinner"
                  onChange={handleSearch}
                />
                {PromptError ? <p>{PromptError}</p> : null}
              </div>
              <div>
                <table className="border-1 table table-striped  text-center">
                  <thead>
                    <tr>
                      <th>No</th>
                      <th>Name</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {myList?.length > 0 &&
                      myList.map((item, index) => {
                        // console.log(item);
                        return (
                          <tr key={index}>
                             <td>{(modalCurrentPage - 1) * 10 + (index + 1)}</td>
                            <td className="w-[44%]">{item.BasicInformation.name}</td>
                            <td>
                              <button
                                className={`rounded ${
                                  item?.isTopList ? "bg-danger" : "bg-success"
                                } text-white px-1 py-1 mr-1 mb-1`}
                                style={{ width: "59px",fontSize:"12px" }}
                                onClick={() => handleDeal2(item?._id)}
                              >
                                 {item?.isTopList ? "Remove" : "Top Lists"}
                              </button>
                              <button
                                className="btn btn-info mr-1 px-1 py-1 mb-1"
                                onClick={() => navigate(`/admin/property/${item._id}/detail`)}
                                style={{ width: "59px",fontSize:"12px" }}
                              >
                                {/* <span className="fas fa-info-circle"></span>{" "} */}
                                Detail
                              </button>
                              <button
                                className="btn btn-primary mr-1 mb-1  px-1 py-1"
                                onClick={() => navigate(`/admin/property/${item._id}`)}
                                style={{ width: "59px",fontSize:"12px" }}
                              >
                                Edit
                              </button>
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
                <div className="mb-4">
                  {totalPage === 1 ? null : (
                    <nav aria-label="Page navigation">
                      <ul className="pagination justify-content-center">
                        <li
                          className={`page-item ${modalCurrentPage === 1 ? "disabled" : ""
                            }`}
                        >
                          <button
                            className="page-link"
                            onClick={() => handleModalPageChange(modalCurrentPage - 1)}
                          >
                            Previous
                          </button>
                        </li>
                        {[...Array(totalPage)].map((_, index) => (
                          <li
                            key={index}
                            className={`page-item ${modalCurrentPage === index + 1 ? "active" : ""
                              }`}
                          >
                            <button
                              className="page-link"
                              onClick={() => handleModalPageChange(index + 1)}
                            >
                              {index + 1}
                            </button>
                          </li>
                        ))}
                        <li
                          className={`page-item ${modalCurrentPage === totalPage ? "disabled" : ""
                            }`}
                        >
                          <button
                            className="page-link"
                            onClick={() => handleModalPageChange(modalCurrentPage + 1)}
                          >
                            Next
                          </button>
                        </li>
                      </ul>
                    </nav>
                  )}
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
        </Modal.Footer>
      </Modal>
      <div style={{ display: "flex" }}>
        <AdminSider
          url={props.url}
          isOpen={sidebarOpen}
          onStateChange={(state) => setSidebarOpen(state.isOpen)}
          collapsed={collapsed}
          toggleCollapsed={toggleCollapsed}
        />
        <div style={{ flex: 1, padding: "20px", overflowX: "scroll" }}>
          <div className="text-center " style={{ margin: "20px" }}>
            <h2>Top Listings Properties</h2>
          </div>
          <div className="flex justify-content-end w-100">
            <button
              type="button"
              className="btn btn-success"
              onClick={handleOpenModal}
            >
              <span className="fa fa-plus"></span> Add Property
            </button>
          </div>
          <div
            className="container table-responsive"
            style={{ overflowX: "scroll" }}
          >
            <table className="table table-striped table-bordered text-center">
              <thead>
                <tr>
                  <th>No</th>
                  {/* <th>ID</th> */}
                  <th>Name</th>
                  <th>Type</th>
                  {/* <th>Status</th> */}
                  <th>Price</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {data?.length === 0 && flag ? (
                  <tr className="text-center">
                    <td colSpan="9">No Data</td>
                  </tr>
                ) : data.length === 0 && flag === false ? (
                  <tr className="text-center">
                    <td colSpan="9">Loading ...</td>
                  </tr>
                ) : (
                  data?.map((res, key) => {
                    return (
                      <tr key={key}>
                       <td>{(currentPageList - 1) * 10 + (key + 1)}</td>
                        <td>
                          {res.BasicInformation.name.slice(0, 10) + "..."}
                        </td>
                        <td>{res.BasicInformation.type}</td>
                        <td>
                          {res.BasicInformation.listingType === "Rent"
                            ? `${res.BasicInformation.currency}${res.BasicInformation.rentalPrice}/${res.BasicInformation.period}`
                            : `${res.BasicInformation.currency}${res.BasicInformation.price}`}
                        </td>
                        <td>
                          <button
                            className={`rounded ${
                              res?.isTopList ? "bg-danger" : "bg-success"
                            } text-white px-3 py-2 `}
                            style={{ width: "150px" }}
                            onClick={() => handleDeal(res?._id)}
                          >
                            {res?.isTopList ? "Remove" : "Top Lists"}
                          </button>
                        </td>
                      </tr>
                    );
                  })
                )}
              </tbody>
            </table>
          </div>
          <div className="mb-4">
            {totalPage === 1 ? null : (
              <nav aria-label="Page navigation">
                <ul className="pagination justify-content-center">
                  <li
                    className={`page-item ${
                      currentPageList === 1 ? "disabled" : ""
                    }`}
                  >
                    <button
                      className="page-link"
                      onClick={() => handlePageChange(currentPageList - 1)}
                    >
                      Previous
                    </button>
                  </li>
                  {[...Array(totalPage)].map((_, index) => (
                    <li
                      key={index}
                      className={`page-item ${
                        currentPageList === index + 1 ? "active" : ""
                      }`}
                    >
                      <button
                        className="page-link"
                        onClick={() => handlePageChange(index + 1)}
                      >
                        {index + 1}
                      </button>
                    </li>
                  ))}
                  <li
                    className={`page-item ${
                      currentPageList === totalPage ? "disabled" : ""
                    }`}
                  >
                    <button
                      className="page-link"
                      onClick={() => handlePageChange(currentPageList + 1)}
                    >
                      Next
                    </button>
                  </li>
                </ul>
              </nav>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
