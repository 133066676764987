import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useToast } from "@chakra-ui/react";
import axios from "axios";
import AdminHeader from "./../layouts/AdminHeader";
import AdminSider from "./../layouts/AdminSider";
import convertToBase64 from "../../helper/convert";
import BASE_URL from "../baseUrl/baseUrl";
import { State } from "country-state-city";
import { Select } from "antd";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import {
  CitySelect,
  CountrySelect,
  StateSelect,
  LanguageSelect,
} from "react-country-state-city";

import "react-country-state-city/dist/react-country-state-city.css";
const AdminagentSaysCreate = (props) => {
  const [countryid, setCountryid] = useState(0);
  const [stateid, setstateid] = useState(0);
  const { Option } = Select;
  const navigate = useNavigate();
  const toast = useToast();
  const [lastname, setlastname] = useState("");
  const [city, setCity] = useState("");
  const [country, setCountry] = useState();
  const [name, setName] = useState("");
  const [area, setArea] = useState("");
  const [telephoneNo, setTelephoneNo] = useState("");
  const [chatId, setChatId] = useState("");
  const [telegramId, setTelegramId] = useState("");
  const [weChatId, setWeChatId] = useState("");
  const [lineId, setLineId] = useState("");
  const [realEstateType, setRealEstateType] = useState();
  const [img, setImg] = useState("");
  const [flag, setFlag] = useState(false);
  const [error, setError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [icon, setIcon] = useState("");
  const [collapsed, setCollapsed] = useState(false);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [iconUrl, setIconUrl] = useState("");
  const [imgUrl, setImgUrl] = useState("");
  const [provinces, setProvinces] = useState([]);
  const [countryCode, setCountryCode] = useState("th");
  const [toggleLineId, setToggleLineId] = useState(true);
  const [toggleWechatId, setToggleWechatId] = useState(true);
  const [toggleAgentWhatsApp, setToggleAgentWhatsApp] = useState(true);
  const [toggleTelephone, setTelephone] = useState(true);
  const [toggleTelegram, settoggleTelegram] = useState(true);
  const [state, setState] = useState('');
  useEffect(() => {
    const allStates = State.getAllStates();
    console.log(allStates);
    setProvinces(allStates);
  }, []);
  const onCancel = () => {
    navigate("/admin/agentsays");
  };
  const postData = async () => {
    console.log(city,country);
    if (name === "") {
      toast({
        title: "Error",
        description: "Enter Name",
        status: "error",
        duration: 2000,
        variant: "left-accent",
        position: "top-right",
        isClosable: true,
      });
      return false;
    }
    if (!img) {
      toast({
        title: "Error",
        description: "Select Image",
        status: "error",
        duration: 2000,
        variant: "left-accent",
        position: "top-right",
        isClosable: true,
      });
      return false;
    }
    if (!lastname) {
      toast({
        title: "Error",
        description: "Enter Lastname",
        status: "error",
        duration: 2000,
        variant: "left-accent",
        position: "top-right",
        isClosable: true,
      });
      return false;
    }
    if (!country) {
      toast({
        title: "Error",
        description: "Enter Country",
        status: "error",
        duration: 2000,
        variant: "left-accent",
        position: "top-right",
        isClosable: true,
      });
      return false;
    }
    if (!chatId) {
      toast({
        title: "Error",
        description: "Enter Chatid",
        status: "error",
        duration: 2000,
        variant: "left-accent",
        position: "top-right",
        isClosable: true,
      });
      return false;
    }
    if (!city) {
      toast({
        title: "Error",
        description: "Enter City",
        status: "error",
        duration: 2000,
        variant: "left-accent",
        position: "top-right",
        isClosable: true,
      });
      return false;
    }
    if (!realEstateType) {
      toast({
        title: "Error",
        description: "Enter Real State Type",
        status: "error",
        duration: 2000,
        variant: "left-accent",
        position: "top-right",
        isClosable: true,
      });
      return false;
    }
    if (!telephoneNo) {
      toast({
        title: "Error",
        description: "Enter Telephone No",
        status: "error",
        duration: 2000,
        variant: "left-accent",
        position: "top-right",
        isClosable: true,
      });
      return false;
    }
    if (!area) {
      toast({
        title: "Error",
        description: "Enter Area",
        status: "error",
        duration: 2000,
        variant: "left-accent",
        position: "top-right",
        isClosable: true,
      });
      return false;
    }
    const userInfo = localStorage.getItem("userInfo");
    const userInfoParsed = JSON.parse(userInfo);
    const formData = new FormData();
    formData.append("name", name);
    formData.append("lastname", lastname);
    formData.append("city", city || "");
    formData.append("country", country);
    formData.append("tel", telephoneNo);
    formData.append("countryCode", countryCode);
    formData.append("whatsappId", chatId);
    formData.append("weChatId", weChatId);
    formData.append("lineId", lineId);
    formData.append("telegramId", telegramId);
    formData.append("area", area);
    formData.append("image", img);
    formData.append("state", state || "");
    formData.append("countryId", countryid || "1");
    realEstateType?.forEach((element, index) => {
      formData.append(`realEstateType[${index}]`, element);
    });
    formData.append("user", userInfoParsed._id);
    setFlag(true);
    await axios
      .post(`${BASE_URL}/Agent/create`, formData)
      .then((res) => {
        setFlag(false);
        toast({
          title: "Success",
          description: "It has been saved successfully.",
          status: "success",
          duration: 2000,
          variant: "left-accent",
          position: "top-right",
          isClosable: true,
        });
        navigate("/admin/agentsays");
      })
      .catch((err) => {
        setFlag(false);
        setError(true);
        setErrorMsg(err);
      });
  };
  const onUploadIcon = async (e) => {
    const base64 = await convertToBase64(e.target.files[0]);
    setIconUrl(base64);
    setIcon(e.target.files[0]);
  };
  const onUploadImage = async (e) => {
    const base64 = await convertToBase64(e.target.files[0]);
    setImgUrl(base64);
    setImg(e.target.files[0]);
  };

  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
  };

  const handleCountryChange = (e) => {
    setCountryCode(e.target.value);
  };

  const countrycode = [
    "+966", // Saudi Arabia
    "+1", // United States
    "+44", // United Kingdom
    "+92", // Pakistan
    "+91", // India
    "+33", // France
    "+49", // Germany
    "+81", // Japan
    "+86", // China
    "+61", // Australia
    "+34", // Spain
    "+39", // Italy
    "+7", // Russia
    "+82", // South Korea
    "+52", // Mexico
    "+55", // Brazil
    "+971", // United Arab Emirates
    "+968", // Oman
    "+20", // Egypt
    "+27", // South Africa
    "+30", // Greece
    "+31", // Netherlands
    "+32", // Belgium
    "+46", // Sweden
    "+47", // Norway
    "+48", // Poland
    "+54", // Argentina
    "+56", // Chile
    "+57", // Colombia
    "+58", // Venezuela
    "+60", // Malaysia
    "+63", // Philippines
    "+64", // New Zealand
    "+65", // Singapore
    "+66", // Thailand
    "+81", // Japan
    "+82", // South Korea
    "+84", // Vietnam
    "+86", // China
    "+90", // Turkey
    "+91", // India
    "+92", // Pakistan
    "+93", // Afghanistan
    "+94", // Sri Lanka
    "+95", // Myanmar
    "+98", // Iran
    "+212", // Morocco
    "+213", // Algeria
    "+216", // Tunisia
    "+218", // Libya
    "+220", // Gambia
    "+221", // Senegal
    "+222", // Mauritania
    "+223", // Mali
    "+224", // Guinea
    "+225", // Ivory Coast
    "+226", // Burkina Faso
    "+227", // Niger
    "+228", // Togo
    "+229", // Benin
    "+230", // Mauritius
    "+231", // Liberia
    "+232", // Sierra Leone
    "+233", // Ghana
    "+234", // Nigeria
    "+235", // Chad
    "+236", // Central African Republic
    "+237", // Cameroon
    "+238", // Cape Verde
    "+239", // Sao Tome and Principe
    "+240", // Equatorial Guinea
    "+241", // Gabon
    "+242", // Republic of the Congo
    "+243", // Democratic Republic of the Congo
    "+244", // Angola
    "+245", // Guinea-Bissau
    "+246", // British Indian Ocean Territory
    "+247", // Ascension Island
    "+248", // Seychelles
    "+249", // Sudan
    "+250", // Rwanda
    "+251", // Ethiopia
    "+252", // Somalia
    "+253", // Djibouti
    "+254", // Kenya
    "+255", // Tanzania
    "+256", // Uganda
    "+257", // Burundi
    "+258", // Mozambique
    "+260", // Zambia
    "+261", // Madagascar
    "+262", // Reunion
    "+263", // Zimbabwe
    "+264", // Namibia
    "+265", // Malawi
    "+266", // Lesotho
    "+267", // Botswana
    "+268", // Eswatini
    "+269", // Comoros
  ];
  const handleTypesChange = (value) => {
    setRealEstateType(value);
  };
  return (
    <div>
      <Helmet>
        <title>Buy Home For Less | Admin People Says Create</title>
        <meta name="description" content="#" />
      </Helmet>
      <AdminHeader collapsed={collapsed} toggleCollapsed={toggleCollapsed} />
      <div style={{ display: "flex" }}>
        <AdminSider
          url={props.url}
          isOpen={sidebarOpen}
          onStateChange={(state) => setSidebarOpen(state.isOpen)}
          collapsed={collapsed}
          toggleCollapsed={toggleCollapsed}
        />
        <div style={{ flex: 1, padding: "20px" }}>
          <div className="text-center" style={{ margin: "20px" }}>
            <h2>Create Agent</h2>
          </div>
          <div
            className="acr-user-content"
            style={{
              display: "flex",
              justifyContent: "center",
              marginLeft: sidebarOpen ? "20vh" : "0",
            }}
          >
            <form
              onSubmit={(e) => {
                e.preventDefault();
              }}
              style={{
                width: "70%",
                padding: "2%",
              }}
            >
              <div className="form-group">
                <label>Name</label>
                <input
                  type="text"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  className="form-control form-control-light"
                  placeholder="Name"
                  name="name"
                />
              </div>
              <div className="form-group">
                <label>Last Name</label>
                <input
                  type="text"
                  value={lastname}
                  onChange={(e) => setlastname(e.target.value)}
                  className="form-control form-control-light"
                  placeholder="Last Name"
                  name="lastname"
                />
              </div>
              <div className="form-group">
                {/* <label>Province/City</label> */}
                {/* <input
                  type="text"
                  value={city}
                  onChange={(e) => setCity(e.target.value)}
                  className="form-control form-control-light"
                  placeholder="Province/City"
                  name="city"
                /> */}
                <div className="form-group mt-3">
                <h6 className="my-2">Country </h6>
                  {/* <input
                    type="text"
                    value={country}
                    onChange={(e) => setCountry(e.target.value)}
                    className="form-control form-control-light"
                    placeholder="Country"
                    name="country"
                  /> */}
                  <CountrySelect
                    onChange={(e) => {
                      console.log(e);
                      setCountryid(e.id);
                      setCountry(e?.name)
                    }}
                    placeHolder="Select Country"
                  />
                   <h6 className="my-2">Province/State </h6>
                  <StateSelect
                    countryid={countryid}
                    onChange={(e) => {
                      setstateid(e.id);
                      setState(e?.name)
                    }}
                    placeHolder="Select State"
                  />
                  <h6 className="my-2">Amphor/Khet </h6>
                  <CitySelect
                    countryid={countryid}
                    stateid={stateid}
                    onChange={(e) => {
                      setCity(e?.name)
                    }}
                    placeHolder="Select City"
                  />
                </div>
              </div>
              {/* <div className="form-group mt-3 position-relative">
                        <label>Tel Number</label>
                        {toggleTelephone ? (
                          <PhoneInput
                          country={"th"}
                          value={agentTel}
                          onChange={(phone) => setAgentTel(phone)}
                          inputStyle={{ width: "100%" }}
                          placeholder="WeChat ID"
                        />
                        ) : (
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Tel Number"
                            value={agentTel}
                            onChange={(e)=>setAgentTel(e.target.value)}
                          />
                        )}
                        <button
                          onClick={() => setTelephone((prev) => !prev)}
                          className={` ${toggleTelephone?('border-2 bg-[#519fff] border-white rounded-lg p-2 text-white'):'bg-[white] text-black rounded-lg border-2 border-color-black mt-1 p-2'}`}
                        >
                          {toggleTelephone
                            ? "Switch to Text Input"
                            : "Switch to Phone Input"}
                        </button>
                      </div> */}
              <div className="form-group mt-3 position-relative">
                <label>Tel Number</label>
                <div className="input-group">
                  {/* <select
                    value={countryCode}
                    onChange={handleCountryChange}
                    style={{ border: "1px solid #e5e1dc", padding: "10px" }}
                  >
                    {countrycode.map((item, index) => (
                      <option key={index} value={item}>
                        {item}
                      </option>
                    ))}
                  </select>
                  <input
                    type="text"
                    value={telephoneNo}
                    onChange={(e) => setTelephoneNo(e.target.value)}
                    className="form-control form-control-light"
                    placeholder="Tel Number"
                    name="TelNumber"
                  /> */}
                  {toggleTelephone ? (
                    <PhoneInput
                      country={countryCode} // Set default country code, e.g., "cn" for China
                      value={telephoneNo}
                      name="whatsappId"
                      onChange={(phone) => setTelephoneNo(phone)}
                      inputStyle={{ width: "100%" }} // Set the input width to match other form fields
                      placeholder="whatsapp Id" // Placeholder text for the input field
                    />
                  ) : (
                    <input
                      type="text"
                      className="form-control w-100"
                      style={{ width: "100%" }}
                      placeholder="Tel Number"
                      value={telephoneNo}
                      onChange={(e) => setTelephoneNo(e.target.value)}
                    />
                  )}
                  <button
                    onClick={() => setTelephone((prev) => !prev)}
                    className={` ${
                      toggleTelephone
                        ? "border-2 bg-[#519fff] border-white rounded-lg p-2 text-white"
                        : "bg-[white] text-black rounded-lg border-2 border-color-black mt-1 p-2"
                    }`}
                  >
                    {toggleTelephone
                      ? "Switch to Text Input"
                      : "Switch to Phone Input"}
                  </button>
                </div>
              </div>
              <div className="form-group row">
                {/* <div className="col-md-3">
                  <label>WhatsApp ID</label>
                  <input
                    type="text"
                    value={chatId}
                    onChange={(e) => setChatId(e.target.value)}
                    className="form-control form-control-light"
                    placeholder="WhatsApp ID"
                    name="whatsappId"
                  />
                </div> */}
                <div className="col-md-3">
                  <label>WhatsApp ID/Username</label>
                  {/* <input
                          type="text"
                          className="form-control"
                          placeholder="Line Id"
                          name="Line Id"
                          value={lineid}
                          onChange={(e) => setlineid(e.target.value)}
                        /> */}
                  {toggleAgentWhatsApp ? (
                    <PhoneInput
                      country={countryCode} // Set default country code, e.g., "cn" for China
                      value={weChatId}
                      name="whatsappId"
                      onChange={(phone) => setWeChatId(phone)}
                      inputStyle={{ width: "100%" }} // Set the input width to match other form fields
                      placeholder="Whatsapp Id" // Placeholder text for the input field
                    />
                  ) : (
                    <input
                      type="text"
                      className="form-control w-100"
                      style={{ width: "100%" }}
                      placeholder="whatsapp Id"
                      value={weChatId}
                      onChange={(e) => setWeChatId(e.target.value)}
                    />
                  )}
                  <button
                    onClick={() => setToggleAgentWhatsApp((prev) => !prev)}
                    className={` ${
                      toggleAgentWhatsApp
                        ? "border-2 bg-[#519fff] border-white rounded-lg p-2 text-white"
                        : "bg-[white] text-black rounded-lg border-2 border-color-black mt-1 p-2"
                    }`}
                  >
                    {toggleAgentWhatsApp
                      ? "Switch to Text Input"
                      : "Switch to Phone Input"}
                  </button>
                </div>
                <div className="col-md-3">
                  <label>WeChat ID / Username</label>
                  <div className="input-group">
                    {/* <select
                    value={countryCode}
                    onChange={handleCountryChange}
                    style={{ border: "1px solid #e5e1dc", padding: "10px" }}
                  >
                    {countrycode.map((item, index) => (
                      <option key={index} value={item}>
                        {item}
                      </option>
                    ))}
                  </select>
                  <input
                    type="text"
                    value={telephoneNo}
                    onChange={(e) => setTelephoneNo(e.target.value)}
                    className="form-control form-control-light"
                    placeholder="Tel Number"
                    name="TelNumber"
                  /> */}
                    {toggleWechatId ? (
                      <PhoneInput
                        country={countryCode} // Set default country code, e.g., "cn" for China
                        value={chatId}
                        name="whatsappId"
                        onChange={(phone) => setChatId(phone)}
                        inputStyle={{ width: "100%" }} // Set the input width to match other form fields
                        placeholder="We chat Id" // Placeholder text for the input field
                      />
                    ) : (
                      <input
                        type="text"
                        className="form-control w-100"
                        style={{ width: "100%" }}
                        placeholder="We chat Id"
                        value={chatId}
                        onChange={(e) => setChatId(e.target.value)}
                      />
                    )}
                    <button
                      onClick={() => setToggleWechatId((prev) => !prev)}
                      className={` ${
                        toggleWechatId
                          ? "border-2 bg-[#519fff] border-white rounded-lg p-2 text-white"
                          : "bg-[white] text-black rounded-lg border-2 border-color-black mt-1 p-2"
                      }`}
                    >
                      {toggleWechatId
                        ? "Switch to Text Input"
                        : "Switch to Phone Input"}
                    </button>
                  </div>
                </div>
                {/* <div className="col-md-3">
                  <label>WeChat ID</label>
                  <input
                    type="text"
                    value={weChatId}
                    onChange={(e) => setWeChatId(e.target.value)}
                    className="form-control form-control-light"
                    placeholder="WeChat ID"
                    name="wechatId"
                  />
                </div> */}

                {/* <div className="col-md-3">
                  <label>LINE ID</label>
                  <input
                    type="text"
                    value={lineId}
                    onChange={(e) => setLineId(e.target.value)}
                    className="form-control form-control-light"
                    placeholder="LINE ID"
                    name="lineId"
                  />
                </div> */}
                <div className="col-md-3">
                  <label>Line Id / Username</label>
                  {/* <input
                          type="text"
                          className="form-control"
                          placeholder="Line Id"
                          name="Line Id"
                          value={lineid}
                          onChange={(e) => setlineid(e.target.value)}
                        /> */}
                  {toggleLineId ? (
                    <PhoneInput
                      country={"th"} // Set default country code, e.g., "cn" for China
                      value={lineId}
                      name="Line Id"
                      onChange={(phone) => setLineId(phone)}
                      inputStyle={{ width: "100%" }} // Set the input width to match other form fields
                      placeholder="Line Id" // Placeholder text for the input field
                    />
                  ) : (
                    <input
                      type="text"
                      className="form-control w-100"
                      style={{ width: "100%" }}
                      placeholder="Line Id"
                      value={lineId}
                      onChange={(e) => setLineId(e.target.value)}
                    />
                  )}
                  <button
                    onClick={() => setToggleLineId((prev) => !prev)}
                    className={` ${
                      toggleLineId
                        ? "border-2 bg-[#519fff] border-white rounded-lg p-2 text-white"
                        : "bg-[white] text-black rounded-lg border-2 border-color-black mt-1 p-2"
                    }`}
                  >
                    {toggleLineId
                      ? "Switch to Text Input"
                      : "Switch to Phone Input"}
                  </button>
                </div>
                {/* <div className="col-md-3">
                  <label>Telegram ID</label>
                  <input
                    type="text"
                    value={telegramId}
                    onChange={(e) => setTelegramId(e.target.value)}
                    className="form-control form-control-light"
                    placeholder="Telegram ID"
                    name="telegramId"
                  />
                </div> */}
                <div className="col-md-3 ">
                  <label>Telegram ID / Username</label>
                  {/* <input
                          type="text"
                          className="form-control"
                          placeholder="Line Id"
                          name="Line Id"
                          value={lineid}
                          onChange={(e) => setlineid(e.target.value)}
                        /> */}
                  {toggleTelegram ? (
                    <PhoneInput
                      country={"th"} // Set default country code, e.g., "cn" for China
                      value={telegramId}
                      name="telegramId"
                      onChange={(phone) => setTelegramId(phone)}
                      inputStyle={{ width: "100%" }} // Set the input width to match other form fields
                      placeholder="Telegram ID" // Placeholder text for the input field
                    />
                  ) : (
                    <input
                      type="text"
                      className="form-control w-100"
                      style={{ width: "100%" }}
                      placeholder="Telegram ID"
                      value={telegramId}
                      onChange={(e) => setTelegramId(e.target.value)}
                    />
                  )}
                  <button
                    onClick={() => settoggleTelegram((prev) => !prev)}
                    className={` ${
                      toggleTelegram
                        ? "border-2 bg-[#519fff] border-white rounded-lg p-2 text-white"
                        : "bg-[white] text-black rounded-lg border-2 border-color-black mt-1 p-2"
                    }`}
                  >
                    {toggleTelegram
                      ? "Switch to Text Input"
                      : "Switch to Phone Input"}
                  </button>
                </div>
              </div>
              <div className="form-group">
                <label>What area do you want to sell real estate?</label>
                <input
                  type="text"
                  value={area}
                  onChange={(e) => setArea(e.target.value)}
                  className="form-control form-control-light"
                  placeholder="area"
                  name="area"
                />
              </div>
              <div className="form-group">
                <label>Type of real estate you're interested in selling:</label>
                {/* <select
                  className="form-control"
                  value={realEstateType}
                  onChange={(e) => setRealEstateType(e.target.value)}
                >
                  <option value={"Rresidential"}>Residential</option>
                  <option value={"commercial"}>Commercial</option>
                  <option value={"business"}>Business</option>
                </select> */}
                <Select
                  mode="multiple"
                  placeholder="Select an option"
                  style={{ width: "100%" }}
                  onChange={handleTypesChange}
                  value={realEstateType}
                >
                  <Option value={"Residential"}>Residential</Option>
                  <Option value={"Commercial"}>Commercial</Option>
                  <Option value={"Villa"}>Villa</Option>
                  <Option value={"Resort"}>Resort</Option>
                  <Option value={"Hotel"}>Hotel</Option>
                  <Option value={"Condo"}>Condo</Option>
                  <Option value={"Appartment"}>Appartment</Option>
                  <Option value={"land"}>Land</Option>
                </Select>
              </div>
              <div className="form-group row">
                <label htmlFor="img" className="cursor-pointer">
                  <i className="fas fa-upload"></i> Select Image
                </label>
                <input
                  onChange={onUploadImage}
                  type="file"
                  id="img"
                  name="img"
                  style={{ display: "none" }}
                />
                {imgUrl ? (
                  <img
                    src={`${imgUrl}`}
                    alt="NoImage"
                    style={{ width: "200px" }}
                  />
                ) : (
                  <></>
                )}
              </div>
              <div className="form-group text-right">
                {flag ? (
                  <button type="Submit" disabled className="btn btn-primary">
                    <span className="fa fa-save"></span> Saving...
                  </button>
                ) : (
                  <button
                    type="Submit"
                    onClick={() => postData()}
                    className="btn btn-primary"
                  >
                    <span className="fa fa-save"></span> Save
                  </button>
                )}
                <button
                  type="button"
                  className="btn btn-default"
                  onClick={() => onCancel()}
                >
                  <span className="fa fa-reply"></span> Cancel
                </button>
              </div>
            </form>
          </div>

          <div style={{ display: "flex", justifyContent: "center" }}>
            {error ? (
              <div
                style={{
                  marginTop: "20px",
                  display: "flex",
                  justifyContent: "space-between",
                  width: "70%",
                  backgroundColor: "#FF3131",
                  color: "white",
                  padding: "10px 20px 10px 20px",
                  borderRadius: "5px",
                  alignItems: "center",
                }}
              >
                <span>{error ? `${errorMsg}` : ""}</span>
                <div
                  style={{
                    cursor: "pointer",
                    display: "flex",
                    justifyContent: "center",
                    border: "white 2px solid",
                    borderRadius: "30px",
                    width: "40px",
                    backgroundColor: "#FF3131",
                    height: "40px",
                  }}
                  onClick={() => {
                    setError(false);
                  }}
                >
                  <p
                    style={{
                      color: "white",
                      alignItems: "center",
                      marginTop: "3px",
                    }}
                  >
                    x
                  </p>
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminagentSaysCreate;
