import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import AdminHeader from "../layouts/AdminHeader";
import AdminSider from "../layouts/AdminSider";
import axios from "axios";
import BASE_URL from "../baseUrl/baseUrl";
import { Modal } from "react-bootstrap";
import { useToast } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";

export default function AdminBestdeal(props) {
  const [collapsed, setCollapsed] = useState(false);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [data, setData] = useState([]);
  const [flag, setFlag] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, settotalPage] = useState();
  const [showModal, setShowModal] = useState(false);
  const [prompt, setPrompt] = useState("");
  const [promptError, setPromptError] = useState("");
  const [searchedData, setSearchedData] = useState();
  const [modalCurrentPage, setModalCurrentPage] = useState(1);
  const [modalTotalPage, setModalTotalPage] = useState();
  const navigate = useNavigate();
  //   const [bestDeal, setbestDeal] = useState([]);
  const toast = useToast();
  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
  };
  const limit = 10;
  useEffect(() => {
    if(currentPage){
      getData(currentPage);
      return
    }
  }, [props.type,currentPage]);
  // useEffect(() => {
  //   if(modalCurrentPage){
  //     getData(modalCurrentPage);
  //     return
  //   }
  // }, [props.type,modalCurrentPage]);

  const getData = async (page) => {
    // const response= await axios.get(`${BASE_URL}/admin/get-Allproperties/${props?.type}?page=${page}&limit=${limit}`)
    const response = await axios.get(
      `${BASE_URL}/admin/get-Allproperties/${props.type}?page=${
        page
      }&limit=${limit}`
    );
    if (response) {
      setFlag(true);
      console.log(response?.data?.pagination?.totalPages);
      settotalPage(response?.data?.pagination?.totalPages);
      setModalTotalPage(response?.data?.pagination?.totalPages);
      setData(response?.data?.result);
      setSearchedData(response?.data?.result);
    }
  };
  // const itemsPerPage = 10;
  // const totalPages = Math.ceil(data.length / itemsPerPage);
  // const startIndex = (currentPage - 1) * itemsPerPage;
  // const currentData = data.slice(startIndex, startIndex + itemsPerPage);

  const handlePageChange = (pageNumber) => {
    // console.log(currentPage,pageNumber);
    setCurrentPage(pageNumber);
  };

  const handleDeal = async (id) => {
    try {
      const response = await axios.post(`${BASE_URL}/admin/isbest/${id}`);
      console.log(response);
      if (response) {
        const updatedData = data.map((property) =>
          property._id === id
            ? { ...property, isBest: !property.isBest }
            : property
        );
        setData(updatedData);
        setShowModal(false);
        setPrompt("");
        setPromptError("");
        setSearchedData([]);
        if (
          response?.data?.message ===
          "The property has been designated as a best deal"
        ) {
          toast({
            title: "success",
            description: response?.data?.message,
            status: "success",
            duration: 2000,
            variant: "left-accent",
            position: "top-right",
            isClosable: true,
          });
        } else if (
          response?.data?.message ===
          "The property has been removed from the best deal designation."
        ) {
          console.log("hello");
          toast({
            title: "Remove",
            description: response.data.message,
            status: "error",
            duration: 2000,
            variant: "left-accent",
            position: "top-right",
            isClosable: true,
          });
        } else {
          toast({
            title: "Error",
            description: "Title",
            status: "error",
            duration: 2000,
            variant: "left-accent",
            position: "top-right",
            isClosable: true,
          });
        }
      }
    } catch (error) {}
  };

  const detailProperty = (id) => {
    navigate(`/admin/property/${id}/detail`);
  };

  const editProperty = (id) => {
    navigate(`/admin/property/${id}`);
  };

  const handleCloseModal = () => {
    setCurrentPage(1)
    setShowModal(false);
    setPrompt("");
    setPromptError("");
    setSearchedData([]);
    setModalCurrentPage(1);
  };

  const handleOpenModal = () => {
    setCurrentPage(1)
    handleModalPageChange(1)
    setShowModal(true);
  };

  const handleModalPageChange = (pageNumber) => {
    setModalCurrentPage(pageNumber);

    if (prompt) {
      searchProperty(prompt, pageNumber); // Pass both prompt and pageNumber
    } else {
      getData(pageNumber); // Load all data if search prompt is empty
    }
  };

  const searchProperty = (value, page) => {
    const modallimit = 3;
    if (!value) {
      setPromptError("⚠️ Prompt is required");
      return;
    }
    axios
      .post(
        `${BASE_URL}/admin/searchProperty/${props.type}?page=${page}&limit=${modallimit}`,
        {
          prompt: value,
        }
      )
      .then((res) => {
        setSearchedData(res?.data?.result);
        setModalTotalPage(res?.data?.totalPages);
        setPromptError("");
      })
      .catch((err) => {
        console.log(err, "response");
      });
  };

  const handleInputChange = (e) => {
    const value = e.target.value;
    setPrompt(value);

    if (!value) {
      setModalCurrentPage(1);
      getData(1); // Reload all data if the search field is empty
      setPromptError("");
      setSearchedData([]); // Clear search results
    } else {
      setPromptError("");
      searchProperty(value, modalCurrentPage);
    }
  };

  return (
    <div>
      <Helmet>
        <title>Buy Home For Less | Admin Category Create</title>
        <meta name="description" content="#" />
      </Helmet>
      <AdminHeader collapsed={collapsed} toggleCollapsed={toggleCollapsed} />
      <Modal
        show={showModal}
        onHide={handleCloseModal}
        centered
        backdrop="true"
        keyboard={false}
        dialogClassName="custom-modal"
        size="md"
      >
        <Modal.Header closeButton>
          <Modal.Title className="dashboard-commission-modal">
            Property
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="container">
            <div className="row mt-4">
              <div className="col-lg-12 d-flex align-items-center flex-col">
                <h6 className="me-2">Search Property:</h6>
                <div className="input-group gap-4">
                  <input
                    type="text"
                    value={prompt}
                    onChange={handleInputChange}
                    style={{
                      border: "1px solid black",
                      borderRadius: "5px",
                      padding: "8px",
                    }}
                    className="form-control"
                  />
                </div>
              </div>
              {promptError && <p className="text-danger mt-2">{promptError}</p>}
            </div>
            {searchedData && searchedData.length > 0 && (
              <>
                <table className="table table-striped table-bordered text-center">
                  <thead>
                    <tr>
                      <th>No</th>
                      <th>Name</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {searchedData.length === 0 && flag ? (
                      <tr className="text-center">
                        <td colSpan="9">No Data</td>
                      </tr>
                    ) : searchedData.length === 0 && flag === false ? (
                      <tr className="text-center">
                        <td colSpan="9">Loading ...</td>
                      </tr>
                    ) : (
                      searchedData.map((res, key) => (
                        <tr key={key}>
                           <td>{(modalCurrentPage - 1) * 10 + (key + 1)}</td>
                          <td>
                            {res.BasicInformation.name.slice(0, 10) + "..."}
                          </td>
                          <td className="d-flex gap-2">
                            <button
                              className={`rounded ${
                                res?.isBest ? "bg-danger" : "bg-success"
                              } text-white px-1 py-1 mr-1 mb-1`}
                              style={{ width: "60px", fontSize: "12px" }}
                              onClick={() => handleDeal(res?._id)}
                            >
                              {res?.isBest ? "Remove" : "Best Deal"}
                            </button>
                            <button
                              className="btn btn-info mr-1 px-1 py-1 mb-1"
                              onClick={() => detailProperty(res._id)}
                              style={{ width: "60px", fontSize: "12px" }}
                            >
                              <span className="fas fa-info-circle"></span>{" "}
                              Detail
                            </button>
                            <button
                              className="btn btn-primary mr-1 mb-1  px-1 py-1"
                              onClick={() => editProperty(res._id)}
                              style={{ width: "60px", fontSize: "12px" }}
                            >
                              <span className="fa fa-edit"></span> Edit
                            </button>
                          </td>
                        </tr>
                      ))
                    )}
                  </tbody>
                </table>
                <div className="mb-4">
                  {modalTotalPage === 1 ? null : (
                    <nav aria-label="Page navigation">
                      <ul className="pagination justify-content-center">
                        <li
                          className={`page-item ${
                            modalCurrentPage === 1 ? "disabled" : ""
                          }`}
                        >
                          <button
                            className="page-link"
                            onClick={() => handleModalPageChange(modalCurrentPage - 1)}
                          >
                            Previous
                          </button>
                        </li>
                        {[...Array(modalTotalPage)].map((_, index) => (
                          <li
                            key={index}
                            className={`page-item ${
                              modalCurrentPage === index + 1 ? "active" : ""
                            }`}
                          >
                            <button
                              className="page-link"
                              onClick={() => handleModalPageChange(index + 1)}
                            >
                              {index + 1}
                            </button>
                          </li>
                        ))}
                        <li
                          className={`page-item ${
                            modalCurrentPage === modalTotalPage ? "disabled" : ""
                          }`}
                        >
                          <button
                            className="page-link"
                            onClick={() => handleModalPageChange(modalCurrentPage + 1)}
                          >
                            Next
                          </button>
                        </li>
                      </ul>
                    </nav>
                  )}
                </div>
              </>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
      <div style={{ display: "flex" }}>
        <AdminSider
          url={props.url}
          isOpen={sidebarOpen}
          onStateChange={(state) => setSidebarOpen(state.isOpen)}
          collapsed={collapsed}
          toggleCollapsed={toggleCollapsed}
        />
        <div style={{ flex: 1, padding: "20px", overflowX: "scroll" }}>
          <div className="text-center" style={{ margin: "20px" }}>
            <h2>Listing Properties</h2>
          </div>
          <div className="d-flex justify-content-end mb-2">
            <button
              type="button"
              className="btn btn-success"
              onClick={handleOpenModal}
            >
              <span className="fa fa-plus"></span> Add Property
            </button>
          </div>
          <div
            className="container table-responsive"
            style={{ overflowX: "scroll" }}
          >
            <table className="table table-striped table-bordered text-center">
              <thead>
                <tr>
                  <th>No</th>
                  {/* <th>ID</th> */}
                  <th>Name</th>
                  <th>Type</th>
                  {/* <th>Status</th> */}
                  <th>Price</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {data?.length === 0 && flag ? (
                  <tr className="text-center">
                    <td colSpan="9">No Data</td>
                  </tr>
                ) : data.length === 0 && flag === false ? (
                  <tr className="text-center">
                    <td colSpan="9">Loading ...</td>
                  </tr>
                ) : (
                  data?.map((res, key) => {
                    return (
                      <tr key={key}>
                       <td>{(currentPage - 1) * 10 + (key + 1)}</td>
                        <td>
                          {res.BasicInformation.name.slice(0, 10) + "..."}
                        </td>
                        <td>{res.BasicInformation.type}</td>
                        <td>
                          {res.BasicInformation.listingType === "Rent"
                            ? `${res.BasicInformation.currency}${res.BasicInformation.rentalPrice}/${res.BasicInformation.period}`
                            : `${res.BasicInformation.currency}${res.BasicInformation.price}`}
                        </td>
                        <td>
                          <button
                            className={`rounded ${
                              res?.isBest ? "bg-danger" : "bg-success"
                            } text-white px-3 py-2 `}
                            style={{ width: "150px" }}
                            onClick={() => handleDeal(res?._id)}
                          >
                            {res?.isBest ? "Remove" : "Best Deal"}
                          </button>
                        </td>
                      </tr>
                    );
                  })
                )}
              </tbody>
            </table>
          </div>
          <div className="mb-4">
            {totalPage === 1 ? null : (
              <nav aria-label="Page navigation">
                <ul className="pagination justify-content-center">
                  <li
                    className={`page-item ${
                      currentPage === 1 ? "disabled" : ""
                    }`}
                  >
                    <button
                      className="page-link"
                      onClick={() => handlePageChange(currentPage - 1)}
                    >
                      Previous
                    </button>
                  </li>
                  {[...Array(totalPage)].map((_, index) => (
                    <li
                      key={index}
                      className={`page-item ${
                        currentPage === index + 1 ? "active" : ""
                      }`}
                    >
                      <button
                        className="page-link"
                        onClick={() => handlePageChange(index + 1)}
                      >
                        {index + 1}
                      </button>
                    </li>
                  ))}
                  <li
                    className={`page-item ${
                      currentPage === totalPage ? "disabled" : ""
                    }`}
                  >
                    <button
                      className="page-link"
                      onClick={() => handlePageChange(currentPage + 1)}
                    >
                      Next
                    </button>
                  </li>
                </ul>
              </nav>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
