import React from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import Homefour from "./components/pages/Homefour";
import Chat from "./components/pages/Chat";
import Services from "./components/pages/Services";
import About from "./components/pages/About";
import Retirement from "./components/pages/Agentarchive";
import Submitlisting from "./components/pages/Submitlisting";

import Login from "./components/pages/Login";
import Register from "./components/pages/Register";

import Pricing from "./components/pages/Pricing";
import Sell from "./components/pages/Sell";
import Rent from "./components/pages/Rent";


import Distress from "./components/pages/Distress";
import Planing from "./components/pages/Planing";
import Modification from "./components/pages/Modification";
import Management from "./components/pages/Management";
import Planner from "./components/pages/Planner";

import Property from "./components/pages/Property";
import Terms from "./components/pages/Terms";
import Estate from "./components/pages/Estate";
import Retire from "./components/pages/Retire";

import Mortgage from "./components/pages/Mortgage";
import Invest from "./components/pages/Invest";
import Loans from "./components/pages/Loans";

import Listing from "./components/pages/Listingmap";
import Contact from "./components/pages/Contact";
import ListingDetailsOne from "./components/pages/Listingdetailsone";
import "./index.css";
import PropertyListing from "./components/pages/PropertyListing";
import View from "./components/sections/view-all/View-All";

import AdminLogin from "./components/pages/AdminLogin";
import AdminPanel from "./components/pages/AdminPanel";
import AdminUser from "./components/pages/AdminUser";
import AdminUserCreate from "./components/pages/AdminUserCreate";
import AdminUserEdit from "./components/pages/AdminUserEdit";
import AdminCategoryCreate from "./components/pages/AdminCategoryCreate";
import AdminCategory from "./components/pages/AdminCategory";
import FooterContent from "./components/pages/AdminFooterContent";
import AdminCategoryEdit from "./components/pages/AdminCategoryEdit";
import AdminPromotion from "./components/pages/AdminPromotion";
import AdminProperty from "./components/pages/AdminProperty";
import AdminPropertyCreate from "./components/pages/AdminPropertyCreate";
import AdminPropertyEdit from "./components/pages/AdminPropertyEdit";
import AdminPropertyDetail from "./components/pages/AdminPropertyDetail";
import AdminCurrency from "./components/pages/AdminCurrency";
import AdminCurrencyCreate from "./components/pages/AdminCurrencyCreate";
import AdminCurrencyEdit from "./components/pages/AdminCurrencyEdit";
import AdminFeature from "./components/pages/AdminFeature";
import AdminFeatureCreate from "./components/pages/AdminFeatureCreate";
import AdminFeatureEdit from "./components/pages/AdminFeatureEdit";
import AdminLocation from "./components/pages/AdminLocation";
import AdminLocationCreate from "./components/pages/AdminLocationCreate";
import AdminLocationEdit from "./components/pages/AdminLocationEdit";
import AdminNearbytype from "./components/pages/AdminNearbytype";
import AdminNearbytypeCreate from "./components/pages/AdminNearbytypeCreate";
import AdminNearbytypeEdit from "./components/pages/AdminNearbytypeEdit";
import AdminFaq from "./components/pages/AdminFaq";
import AdminFaqCreate from "./components/pages/AdminFaqCreate";
import AdminFaqEdit from "./components/pages/AdminFaqEdit";
import Termsandcond from "./components/pages/Termsandconditions";
import Privacypolicy from "./components/pages/PrivacyPolicy";
import Refundpolicy from "./components/pages/Refundpolicy";
import Cookiepolicy from "./components/pages/CookiePolicy";
import Sellproperty from "./components/pages/Mortgage";
import Listingwrapper from "./components/sections/listing-details-v1/Listingwrapper";
import AdminFooterCreate from "./components/pages/AdminfooterCreate";
import AdminCreateNewsandBolgs from "./components/pages/AdminCreateNewsandBolgs";
import AdminBlogandNews from "./components/pages/AdminBlogandNews";
import Bloggrid from "./components/pages/Bloggrid";
import Bloglist from "./components/pages/Bloglist";
import AdminBlogEdit from "./components/pages/AdminBlogEdit";
import Adminsays from "./components/pages/Adminsays";
import AdminagentSaysCreate from "./components/pages/AdminagentSaysCreate";
import AdminagentSaysEdit from "./components/pages/AdminagentSaysEdit";
import AdminourMoto from "./components/pages/AdminourMoto";
import AdminCreateourMoto from "./components/pages/AdminCreateourMoto";
import AdmineditOurMoto from "./components/pages/AdmineditOurMoto";
import AdminHeaderContent from "./components/pages/AdminHeaderContent";
import AdminHeaderCreate from "./components/pages/AdminHeaderCreate";
import AdminHeaderEdit from "./components/pages/AdminHeaderEdit";
import Adminbanner from "./components/pages/Adminbanner";
import AdminSublistContent from "./components/pages/AdminSublistContent";
import AdminSublistEdit from "./components/pages/AdminSublistEdit";
import AdminSublistCreate from "./components/pages/AdminSublistCreate";
import AdminItemcontent from "./components/pages/AdminItemcontent";
import AdminItemCreate from "./components/pages/AdminItemCreate";
import AdminItemEdit from "./components/pages/AdminItemEdit";
import Hometwo from "./components/pages/Hometwo";
import Adminpricerange from "./components/pages/Adminpricerange";
import AdminPriceRangeCreate from "./components/pages/AdminPriceRangeCreate";
import AdminSeo from "./components/pages/AdminSeo";
import AdminHeaderReference from "./components/pages/AdminReference";
import AdminTestimonials from "./components/pages/AdminTestimonials";
import AdminTestimonialsCreate from "./components/pages/AdminTestimonialCreate";
import AdminTestimonialsEdit from "./components/pages/AdminTestimonialEdit";
import AdminBestdeal from "./components/pages/AdminBestdeal";
import AdminSchedule from "./components/pages/AdminSchedule";
import AdminScheduleDetail from "./components/pages/AdminScheduleDetail";
import AdminComplain from "./components/pages/AdminComplain";
import AdminComplainDetail from "./components/pages/AdminComplainDetail";
import AdminTopList from "./components/pages/AdminTopList";
import ContactPage from "./components/pages/Contactpage";
import AdminContactDetail from "./components/pages/AdminContactDetail";
import Propertylisting from "./components/layouts/Propertylisting";

const App = () => {
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  return (
    <div className="App">
      {userInfo?.isAdmin ? (
        <Routes>
          <Route path="/home" element={<Homefour />} />
          <Route path="/hometwo" element={<Hometwo />} />
          <Route path="/" element={<AdminPanel />} />
          <Route
            path="/admin/users"
            element={<AdminUser url="/admin/users" />}
          />
          <Route
            path="/admin/user/create"
            element={<AdminUserCreate url="/admin/users" />}
          />
          <Route
            path="/admin/user/:id"
            element={<AdminUserEdit url="/admin/users" />}
          />
          <Route
            path="/admin/Contactpage"
            element={<ContactPage url="/admin/Contactpage" />}
          />
          <Route
            path="/admin/Contactpagedetail"
            element={<AdminContactDetail url="/admin/Contactpagedetail" />}
          />
          <Route
            path="/admin/Propertylisting"
            element={<Propertylisting url="/admin/Propertylisting" />}
          />
          <Route
            path="/admin/categories"
            element={<AdminCategory url="/admin/categories" />}
          />
          <Route
            path="/admin/category/create"
            element={<AdminCategoryCreate url="/admin/categories" />}
          />
          <Route
            path="/admin/bestdealRent"
            element={<AdminBestdeal type="Rent" url="/admin/bestdealRent" />}
          />
          <Route
            path="/admin/bestdealSell"
            element={<AdminBestdeal type="Sell" url="/admin/bestdealSell" />}
          />
          <Route
            path="/admin/topListrent"
            element={<AdminTopList type="Rent" url="/admin/topListrent" />}
          />
          <Route
            path="/admin/topListsell"
            element={<AdminTopList type="Sell" url="/admin/topListsell" />}
          />
          <Route
            path="/admin/footercontent/create"
            element={<AdminFooterCreate url="/admin/footercontent" />}
          />
          <Route
            path="/admin/banner"
            element={<Adminbanner url="/admin/banner" />}
          />
          <Route
            path="/admin/seo"
            element={<AdminSeo url="/admin/seo" />}
          />
          <Route
            path="/admin/category/:id"
            element={<AdminCategoryEdit url="/admin/categories" />}
          />
          <Route
            path="/admin/properties"
            element={<AdminProperty url="/admin/properties" />}
          />
          <Route
            path="/admin/property/create"
            element={<AdminPropertyCreate url="/admin/properties" />}
          />
          <Route
            path="/admin/sellproperty"
            element={<AdminProperty type="Sell" url="/admin/sellproperty" />}
          />
          <Route
            path="/admin/rentproperty"
            element={<AdminProperty type="Rent" url="/admin/rentproperty" />}
          />
          <Route
            path="/admin/property/:id"
            element={<AdminPropertyEdit url="/admin/properties" />}
          />
          <Route
            path="/admin/property/:id/detail"
            element={<AdminPropertyDetail url="/admin/properties" />}
          />
          <Route
            path="/admin/currencies"
            element={<AdminCurrency url="/admin/currencies" />}
          />
          <Route
            path="/admin/pricerange"
            element={<Adminpricerange url="/admin/pricerange" />}
          />
          <Route
            path="/admin/currency/create"
            element={<AdminCurrencyCreate url="/admin/currencies" />}
          />
          <Route
            path="/admin/pricerange/create"
            element={<AdminPriceRangeCreate url="/admin/pricerange" />}
          />
          <Route
            path="/admin/currency/:id"
            element={<AdminCurrencyEdit url="/admin/currencies" />}
          />
          <Route
            path="/admin/features"
            element={<AdminFeature url="/admin/features" />}
          />
          <Route
            path="/admin/feature/create"
            element={<AdminFeatureCreate url="/admin/features" />}
          />
          <Route
            path="/admin/feature/:id"
            element={<AdminFeatureEdit url="/admin/features" />}
          />
          <Route
            path="/admin/locations"
            element={<AdminLocation url="/admin/locations" />}
          />
          <Route
            path="/admin/location/create"
            element={<AdminLocationCreate url="/admin/locations" />}
          />
          <Route
            path="/admin/location/:id"
            element={<AdminLocationEdit url="/admin/locations" />}
          />
          <Route path="/admin/faqs" element={<AdminFaq url="/admin/faqs" />} />
          <Route
            path="/admin/faq/create"
            element={<AdminFaqCreate url="/admin/faqs" />}
          />
          <Route
            path="/admin/faq/:id"
            element={<AdminFaqEdit url="/admin/faqs" />}
          />
          <Route
            path="/admin/nearbytypes"
            element={<AdminNearbytype url="/admin/nearbytypes" />}
          />
          <Route
            path="/admin/nearbytype/create"
            element={<AdminNearbytypeCreate url="/admin/nearbytypes" />}
          />
          <Route
            path="/admin/nearbytype/:id"
            element={<AdminNearbytypeEdit url="/admin/nearbytypes" />}
          />
          <Route
            path="/admin/testimonial"
            element={<AdminTestimonials url="/admin/testimonial" />}
          />
          <Route
            path="/admin/testimonial/create"
            element={<AdminTestimonialsCreate url="/admin/testimonial" />}
          />
          <Route
            path="/admin/testimonial/:id"
            element={<AdminTestimonialsEdit url="/admin/testimonial" />}
          />
          <Route
            path="/admin/blognews/create"
            element={<AdminCreateNewsandBolgs url="admin/blognews/create" />}
          />
          <Route
            path="/admin/blognews"
            element={<AdminBlogandNews url="admin/blognews" />}
          />
          <Route
            path="/admin/agentsays"
            element={<Adminsays url="admin/agentsays" />}
          />
          <Route
            path="/admin/ourmoto"
            element={<AdminourMoto url="admin/ourmoto" />}
          />
          <Route
            path="/admin/ourmoto/create"
            element={<AdminCreateourMoto url="admin/ourmoto/create" />}
          />
          <Route
            path="/admin/agentsays/create"
            element={<AdminagentSaysCreate url="admin/agentsays/create" />}
          />
          <Route
            path="/admin/blognews/:id"
            element={<AdminBlogEdit url="admin/blognews" />}
          />
          <Route
            path="/admin/headercontent"
            element={<AdminHeaderContent url="/admin/headercontent" />}
          />
          <Route
            path="/admin/headercontent/create"
            element={<AdminHeaderCreate url="/admin/headercontent/create" />}
          />
          <Route
            path="/admin/sublist/create"
            element={<AdminSublistCreate url="/admin/sublist/create" />}
          />
          <Route
            path="/admin/item/create"
            element={<AdminItemCreate url="/admin/item/create" />}
          />
          <Route
            path="/admin/headercontent/edit"
            element={<AdminHeaderEdit url="/admin/headercontent/edit" />}
          />

          <Route
            path="/admin/sublist/edit"
            element={<AdminSublistEdit url="/admin/sublist/edit" />}
          />
          <Route
            path="/admin/item/:id"
            element={<AdminItemEdit url="/admin/item/edit" />}
          />

          <Route
            path="/admin/agentsays/:id"
            element={<AdminagentSaysEdit url="admin/agentsays" />}
          />
          <Route
            path="/admin/ourmoto/:id"
            element={<AdmineditOurMoto url="admin/ourmoto" />}
          />
          <Route
            path="/admin/sublistcontent"
            element={<AdminSublistContent url="admin/sublistcontent" />}
          />

          <Route
            path="/admin/itemcontent"
            element={<AdminItemcontent url="admin/itemcontent" />}
          />

          <Route
            path="/admin/reference"
            element={<AdminHeaderReference url="/admin/reference" />}
          />
          <Route
            path="/admin/ScheduleDetail"
            element={<AdminScheduleDetail url="/admin/ScheduleDetail" />}
          />
          <Route
            path="/admin/schedule"
            element={<AdminSchedule url="/admin/schedule" />}
          />
          <Route
            path="/admin/ComplainDetail"
            element={<AdminComplainDetail url="/admin/ComplainDetail" />}
          />
          <Route
            path="/admin/Complain"
            element={<AdminComplain url="/admin/Complain" />}
          />
          <Route
            path="/admin/promotion"
            element={<AdminPromotion url="/admin/promotion" />}
          />
          <Route path="/listingwrapper" element={<Listingwrapper />} />
          <Route path="/submit-listing" element={<Submitlisting />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/agent" element={<Retirement />} />
          <Route path="/services" element={<Services />} />
          <Route path="/buy" element={<Pricing />} />
          <Route path="/sell" element={<Sell />} />
          <Route path="/rent" element={<Rent />} />

          <Route path="/register" element={<Register />} />
          <Route path="/login" element={<Login />} />
          <Route path="/chat" element={<Chat />} />
          <Route exact
            path="/listing-details-v1/:id"
            element={<ListingDetailsOne />}
          />
          <Route path="/property/:type" element={<PropertyListing />} />
          <Route path="/sell/:type" element={<PropertyListing />} />
          <Route path="/rent/:type" element={<PropertyListing />} />
          <Route path="/view-all" element={<View />} />

          <Route path="/Distress" element={<Distress />} />
          <Route path="/Planing" element={<Planing />} />
          <Route path="/Modification" element={<Modification />} />
          <Route path="/Management" element={<Management />} />
          <Route path="/Planner" element={<Planner />} />
          <Route path="/sellproperty" element={<Sellproperty />} />
          <Route path="/Property" element={<Property />} />
          <Route path="/Terms" element={<Terms />} />
          <Route path="/Termsandcond" element={<Termsandcond />} />
          <Route path="/refundpolicy" element={<Refundpolicy />} />
          <Route path="/privacypolicy" element={<Privacypolicy />} />
          <Route path="/cookiepolicy" element={<Cookiepolicy />} />
          <Route path="/Estate" element={<Estate />} />
          <Route path="/Retire" element={<Retire />} />

          <Route path="/Mortgage" element={<Mortgage />} />
          <Route path="/Invest" element={<Invest />} />
          <Route path="/Loans" element={<Loans />} />
          <Route path="/listing-map" element={<Listing />} />
          <Route path="/blog-news" element={<Bloggrid />} />
          <Route path="/blog" element={<Bloglist />} />
          <Route path="*" element={<div>Error 404 Page not found.</div>} />
        </Routes>
      ) : (
        <Routes>
          <Route path="/" element={<AdminLogin />} />




          <Route path="*" element={<div>Error 404 Page not found</div>} />
        </Routes>
      )}
    </div>
  );
};

export default App;
